import React, { forwardRef, useContext } from 'react';
import '../../css/Card/PageContentCard.css';
import PageContentCardHeader from './Headers/PageContentCardHeader';
import PageContentCardFooter from './Footers/PageContentCardFooter';

const PageContentCard = forwardRef(({
    date,
    children,
    handleExportOptionsClick,
    isExportViewMenuOpen,
}, ref) => {


    return (
        <div className="page-content-card" ref={ref}>
            <PageContentCardHeader
                handleExportOptionsClick={handleExportOptionsClick}
                date={date}
                isExportViewMenuOpen={isExportViewMenuOpen}
            />
            {children}
            <PageContentCardFooter date={date} />
        </div>
    );
});

export default PageContentCard;
