import { useContext } from 'react';
import { MenuContext } from '../../context/MenuContext';
import { ReportContext } from '../../context/ReportContext';
import ReportsHeader from '../Header/ReportsHeader';
import 'primereact/resources/themes/lara-light-cyan/theme.css';

const ReportsPane = () => {
    const { selectedMenuItem } = useContext(MenuContext);
    const { selectedReport, errorText, hasMadeFirstRequest, inFlight } =
        useContext(ReportContext);

    const Display = () => {
        if (!selectedReport) {
            return <div>Report not found</div>;
        }

        if (errorText) {
            return <div className="text-lg font-bold text-red-700">{errorText}</div>;
        }

        if (inFlight) {
            return <div>Loading...</div>;
        }

        if (!hasMadeFirstRequest) {
            return <div>Configure the report parameters above and click Run</div>;
        }

        const PageComponent = selectedReport.pageComponent;
        return <PageComponent />;
    };

    return (
        <div className="bg-white rounded-md shadow-md m-4 p-4">
            <h1 className="text-xl font-bold mb-4">{selectedMenuItem.name}</h1>
            <ReportsHeader />
            <Display />
        </div>
    );
};

export default ReportsPane;
