import { useContext, useEffect, useState } from 'react';
import '../../css/ChartTools/CustomModebar.css';
import { ReactComponent as PointerIcon } from '../../images/customModebar/EICustomToolPointer.svg';
import { ReactComponent as ZoomInIcon } from '../../images/customModebar/EICustomToolZoomIn.svg';
import { ReactComponent as ZoomOutIcon } from '../../images/customModebar/EICustomToolZoomOut.svg';
import { ReactComponent as PanIcon } from '../../images/customModebar/EICustomToolPan.svg';
import { ReactComponent as ResetAxesIcon } from '../../images/customModebar/EICustomToolResetAxes.svg';
import { ReactComponent as AutoScaleIcon } from '../../images/customModebar/EICustomToolAutoScale.svg';
import { ReactComponent as ZoomSelectionIcon } from '../../images/customModebar/EICustomToolZoomSelection.svg';
import Plotly from '../../plotly-basic.min.js'
import { EconomicDataContext } from '../../context/EconomicDataContext';
import { MenuContext } from '../../context/MenuContext';

const CustomModebar = ({ selectedTool, setSelectedTool, plotlyGraphId }) => {
	const {
		originalChartData,
		setOriginalChartData,
		zoomedChartData,
		setZoomedChartData,
		zoomedDates,
		setZoomedDates,
	} = useContext(EconomicDataContext);

	const {
			selectedButton,
		} = useContext(MenuContext);

	useEffect(() => {
		resetData();
	}, [selectedButton]);

	const minLimit = new Date('1926-01-01').getTime();
	const maxLimit = new Date().getTime();

	const [currentMin, setCurrentMin] = useState(minLimit);
	const [currentMax, setCurrentMax] = useState(maxLimit);

	const pointer = (elementId) => {
		try {
			setSelectedTool(elementId);
			Plotly.relayout(plotlyGraphId, { dragmode: false });
		} catch (error) { }
	}

	function resetData(){
		setOriginalChartData([]);
		setZoomedChartData([]);
		setZoomedDates([]);
		setCurrentMin(minLimit);
		setCurrentMax(maxLimit);
	}

	function filterDataByDateRange(data, startDate, endDate) {
		const start = new Date(startDate);
		const end = new Date(endDate);

		return data.map(trace => {
			const filteredIndices = trace.x
				.map((date, index) => ({ date: new Date(date), index }))
				.filter(({ date }) => date >= start && date <= end)
				.map(({ index }) => index);

			// Filter hovertext by checking if it contains valid dates in the range for line scatter plots
			const filteredHoverText = trace.hovertext
				? filteredIndices.map(index => {
					const hoverTextEntry = trace.hovertext[index];
					const match = hoverTextEntry.match(/\d{4}-\d{2}-\d{2}/); // Match date strings like '1925-12-31'
					if (match) {
						const hoverDate = new Date(match[0]);
						return hoverDate >= start && hoverDate <= end
							? hoverTextEntry
							: null;
					}
					return null; // Exclude if no valid date is found
				}).filter(text => text !== null) // Remove null entries
				: undefined;

			return {
				...trace,
				x: filteredIndices.map(index => trace.x[index]),
				y: filteredIndices.map(index => trace.y[index]),
				hovertext: trace.type === 'scatter' && trace.mode === 'lines' ? filteredHoverText : trace.hovertext
			};
		});
	}

	const zoomIn = (elementId, incrementFactor = 0.2) => {
		setSelectedTool(elementId);

		let currentXRange = [];

		if(zoomedDates.length > 0){
			currentXRange = [zoomedDates[0], zoomedDates[1]];
			setCurrentMin(zoomedDates[0]);
			setCurrentMax(zoomedDates[1]);
		}else{
			currentXRange = [currentMin, currentMax];
		}

		if (currentXRange.some(value => isNaN(value))) {
			console.error('Invalid x-axis range: ', currentXRange);
			return;
		}

		const xMid = (currentXRange[0] + currentXRange[1]) / 2;

		const xZoomedInRange = [
			xMid - (xMid - currentXRange[0]) * (1 - incrementFactor),
			xMid + (currentXRange[1] - xMid) * (1 - incrementFactor)
		];

		const [newMin, newMax] = [
			Math.max(xZoomedInRange[0], minLimit),
			Math.min(xZoomedInRange[1], maxLimit)
		];

		if (isNaN(newMin) || isNaN(newMax)) {
			console.error('Invalid calculated zoom range:', [newMin, newMax]);
			return;
		}

		setCurrentMin(newMin);
		setCurrentMax(newMax);
		setZoomedDates([newMin, newMax]);

		const dataToFiter = zoomedChartData.length > 0 ? zoomedChartData : originalChartData;
		const zoomedData = filterDataByDateRange(dataToFiter, newMin, newMax);

		setZoomedChartData(zoomedData);
	};


	const zoomOut = (elementId, zoomFactor = 0.2) => {
		setSelectedTool(elementId);

		let currentXRange = [currentMin, currentMax];

		if (currentXRange.some(value => isNaN(value))) {
			console.error('Invalid x-axis range:', currentXRange);
			return;
		}

		const xMid = (currentXRange[0] + currentXRange[1]) / 2;

		const xZoomedOutRange = [
			xMid - (xMid - currentXRange[0]) * (1 + zoomFactor),
			xMid + (currentXRange[1] - xMid) * (1 + zoomFactor)
		];

		const [newMin, newMax] = [
			Math.max(xZoomedOutRange[0], minLimit),
			Math.min(xZoomedOutRange[1], maxLimit)
		];

		if (isNaN(newMin) || isNaN(newMax)) {
			console.error('Invalid calculated zoom range:', [newMin, newMax]);
			return;
		}

		const updatedZoomedData = originalChartData.filter(data =>
			data.x >= newMin && data.x <= newMax
		);

		setCurrentMin(newMin);
		setCurrentMax(newMax);
		setZoomedDates([newMin, newMax]);
		setZoomedChartData(updatedZoomedData);
	};


	const pan = (elementId) => {
		setSelectedTool(elementId);
	}

	const resetAxes = (elementId) => {
		resetData();
	};

	const autoScale = (elementId) => {
		// This tool should put all points on the screen even if the charts default view doenst show all points.
		// This is only really applicable for Commodities standalone currently.
		setOriginalChartData([]);
		setZoomedChartData([]);
		setZoomedDates([minLimit, maxLimit]);
		setCurrentMin(minLimit);
		setCurrentMax();
	}

	const zoomSelection = (elementId) => {
		setSelectedTool(elementId);

		const graphDiv = document.getElementById(plotlyGraphId);

		graphDiv.on('plotly_selected', (eventData) => {
			if (eventData) {
				// Extracting selection range
				const selection = eventData.selections?.[0];
				if (selection) {
					// Parse x0 and x1 into numeric years
					const startYear = new Date(selection.x0).getTime();
					const endYear = new Date(selection.x1).getTime();

					// Update state with new range
					if (currentMin !== startYear) setCurrentMin(startYear);
					if (currentMax !== endYear) setCurrentMax(endYear);
					setZoomedDates([startYear, endYear]);

					// Filter data using the same logic as zoomIn
					const dataToFilter = zoomedChartData.length > 0 ? zoomedChartData : originalChartData;
					const filteredData = filterDataByDateRange(dataToFilter, startYear, endYear);

					setZoomedChartData(filteredData);
				}
			}
		});
	};



	const tools = [
		{ id: 'PointerTool', icon: <PointerIcon alt="Pointer Tool" />, onClick: () => pointer('PointerTool') },
		{ id: 'ZoomInTool', icon: <ZoomInIcon alt="Zoom In Tool" />, onClick: () => zoomIn('ZoomInTool') },
		{ id: 'ZoomOutTool', icon: <ZoomOutIcon alt="Zoom Out Tool" />, onClick: () => zoomOut('ZoomOutTool') },
		{ id: 'PanTool', icon: <PanIcon />, onClick: () => pan('PanTool') },
		{ id: 'ResetAxesTool', icon: <ResetAxesIcon />, onClick: () => resetAxes('ResetAxesTool') },
		{ id: 'AutoScaleTool', icon: <AutoScaleIcon />, onClick: () => autoScale('AutoScaleTool') },
		{ id: 'ZoomSelectionTool', icon: <ZoomSelectionIcon />, onClick: () => zoomSelection('ZoomSelectionTool') }
	];

	return (
		<div className={`custom-modebar`}>
			<div className='custom-modebar-section'>
				{tools.map(tool => (
					<div key={tool.id} className='custom-modebar-container'>
						<button
							id={tool.id}
							className={`custom-modebar-item-container icon ${selectedTool === tool.id ? 'selected' : ''}`}
							onClick={tool.onClick}
						>
							{tool.icon}
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomModebar;
