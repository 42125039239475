import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserFinancialWellnessChange = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'startDate', name: 'From Date', dataType: 'date' },
        { field: 'finishDate', name: 'To Date', dataType: 'date' },
        { field: 'isCurrent', name: 'Current', dataType: 'multiselect' },
        { field: 'debtStudentLoan', name: 'Debt: Student Loan', dataType: 'multiselect' },
        { field: 'debtMortgage', name: 'Debt: Mortgage', dataType: 'multiselect' },
        { field: 'debtAuto', name: 'Debt: Auto', dataType: 'multiselect' },
        { field: 'debtOther', name: 'Debt: Other', dataType: 'multiselect' },
        { field: 'debtNone', name: 'Debt: None', dataType: 'multiselect' },
        { field: 'debtCreditCard', name: 'Debt: Credit Card', dataType: 'multiselect' },
        { field: 'debtFamilyFriend', name: 'Debt: Family/Friend', dataType: 'multiselect' },
        { field: 'debtMedical', name: 'Debt: Medical', dataType: 'multiselect' },
        { field: 'debtPaydayLoan', name: 'Debt: Payday Loan', dataType: 'multiselect' },
        { field: 'debtBusiness', name: 'Debt: Business', dataType: 'multiselect' },
        {
            field: 'debtInstallmentLoan',
            name: 'Debt: Installment Loan',
            dataType: 'multiselect',
        },
        { field: 'debtLineOfCredit', name: 'Debt: Line Of Credit', dataType: 'multiselect' },
        { field: 'debtCollections', name: 'Debt: Collections', dataType: 'multiselect' },
        { field: 'savingRetirement', name: 'Saving: Retirement', dataType: 'multiselect' },
        { field: 'savingCar', name: 'Saving: Car', dataType: 'multiselect' },
        {
            field: 'savingChildCollegeFund',
            name: 'Saving: ChildCollegeFund',
            dataType: 'multiselect',
        },
        { field: 'savingEducation', name: 'Saving: Education', dataType: 'multiselect' },
        { field: 'savingHome', name: 'Saving: Home', dataType: 'multiselect' },
        { field: 'savingParenthood', name: 'Saving: Parenthood', dataType: 'multiselect' },
        { field: 'savingPet', name: 'Saving: Pet', dataType: 'multiselect' },
        {
            field: 'savingStartingBusiness',
            name: 'Saving: Starting Business',
            dataType: 'multiselect',
        },
        { field: 'savingVacation', name: 'Saving: Vacation', dataType: 'multiselect' },
        { field: 'savingWedding', name: 'Saving: Wedding', dataType: 'multiselect' },
        { field: 'savingOther', name: 'Saving: Other', dataType: 'multiselect' },
        { field: 'savingNone', name: 'Saving: None', dataType: 'multiselect' },
        {
            field: 'savingEmergencyFund',
            name: 'Saving: Emergency Fund',
            dataType: 'multiselect',
        },
        { field: 'healthInsurance', name: 'Health Insurance', dataType: 'multiselect' },
        { field: 'payOffBills', name: 'Pay Off Bills', dataType: 'multiselect' },
        {
            field: 'leftoverMoneyMonthly',
            name: 'Leftover Money Monthly',
            dataType: 'multiselect',
        },
        {
            field: 'creditCardCarryBalance',
            name: 'Credit Card Carry Balance',
            dataType: 'multiselect',
        },
        { field: 'emergencyFund', name: 'Emergency Fund', dataType: 'multiselect' },
        { field: 'budget', name: 'Budget', dataType: 'multiselect' },
        { field: 'goals', name: 'Goals', dataType: 'multiselect' },
        { field: 'insuranceNone', name: 'Insurance: None', dataType: 'multiselect' },
        { field: 'insuranceAuto', name: 'Insurance: Auto', dataType: 'multiselect' },
        {
            field: 'insuranceDisability',
            name: 'Insurance: Disability',
            dataType: 'multiselect',
        },
        {
            field: 'insuranceHomeOrRental',
            name: 'Insurance: Home Or Rental',
            dataType: 'multiselect',
        },
        { field: 'insuranceLife', name: 'Insurance: Life', dataType: 'multiselect' },
        {
            field: 'insuranceLongTermCare',
            name: 'Insurance: Long Term Care',
            dataType: 'multiselect',
        },
        { field: 'insuranceUmbrella', name: 'Insurance: Umbrella', dataType: 'multiselect' },
        { field: 'insuranceOther', name: 'Insurance: Other', dataType: 'multiselect' },
        { field: 'will', name: 'Will', dataType: 'multiselect' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.startDate),
        {
            count: true,
        }
    );

    const chartData = [
        {
            name: 'Total Changes',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.count),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Financial Wellness Changes Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserFinancialWellnessChange;
