import { useContext } from 'react';
import { PAGE_TYPE } from '../../constants';
import EducatedInvestorPane from './EducatedInvestorPane';
import ReportsPane from './ReportsPane';
import { MenuContext } from '../../context/MenuContext';
import Header from '../Header/Header';

const DisplayPane = ({ updateCounter }) => {
    const { selectedMenuItem, isPresentationMode } = useContext(MenuContext);

    let pageContents;
    switch (selectedMenuItem.pageType) {
        case PAGE_TYPE.educatedInvestor:
            pageContents = <EducatedInvestorPane updateCounter={updateCounter} />;
            break;
        case PAGE_TYPE.reports:
            pageContents = <ReportsPane />;
            break;
        default:
            pageContents = (
                <div className="text-xl text-A1 flex justify-center items-center w-full h-full">
                    Please select a report from the menu on the left
                </div>
            );
    }

    return (
        <div className="pagecontent-container">
            {!isPresentationMode && <Header />}
            {pageContents}
        </div>
    );
};

export default DisplayPane;
