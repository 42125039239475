import { useEffect, useState } from 'react';
import { getFilterDefaultByDataType } from './primeUtils';

/*
 * @param {Array<Object>} columns - An array of objects defining the configuration for columns.
 * @param {string} columns[].name - The display name of the column.
 * @param {string} columns[].field - The field key corresponding to the column in the data source.
 * @param {"string" | "date" | "multiselect" | "numeric" | "boolean"} columns[].dataType -
 */
const useFilters = (columns) => {
    const initFilters = () => {
        const baseFilters = {};
        columns.forEach((x) => {
            baseFilters[x.field] = getFilterDefaultByDataType(x.dataType);
        });
        return baseFilters;
    };

    const [filters, setFilters] = useState(initFilters);

    const resetFilters = () => {
        setFilters(initFilters());
    };

    return [filters, setFilters, resetFilters];
};

export default useFilters;
