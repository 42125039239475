import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { MenuProvider } from './context/MenuContext';
import { PrimeReactProvider } from 'primereact/api';
import { ReportProvider } from './context/ReportContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from './context/ThemeContext';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
            audience: audience,
            redirect_uri: window.location.origin,
        }}
    >
        <ThemeProvider>
            <MenuProvider>
                <ReportProvider>
                    <PrimeReactProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <App />
                        </LocalizationProvider>
                    </PrimeReactProvider>
                </ReportProvider>
            </MenuProvider>
        </ThemeProvider>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
