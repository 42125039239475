import React, { createContext, useState, useEffect } from 'react';
import { ERROR_TEXT } from '../constants';
import { PAGE_TYPE } from '../constants';
import { useAxiosAuthenticated } from '../useAxiosAuthenticated';

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
    const [errorText, setErrorText] = useState('');

    const { get, tokenError } = useAxiosAuthenticated();
    useEffect(() => {
        if (tokenError) setErrorText(ERROR_TEXT.token);
    }, [tokenError]);

    const defaultCase = {
        id: 'default',
        name: 'Default Page',
        icon: <></>,
        pageType: PAGE_TYPE.default,
    };
    const [selectedMenuItem, setSelectedMenuItem] = useState(defaultCase);

    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const toggleMenuOpen = () => setIsMenuOpen((x) => !x);

    const [isPresentationMode, setIsPresentationMode] = useState(false);
    const togglePresentationMode = () => setIsPresentationMode((x) => !x);

    // Menu options within specific reports but still need global getters/setters
    const [selectedButton, setSelectedButton] = useState('Full Andex');
    const [isWorldEventsMenuVisible, setIsWorldEventsMenuVisible] = useState(false);

    // Check current user's access rights for use as UI hints
    const [clientConsoleAccess, setClientConsoleAccess] = useState({
        educatedInvestorEnabled: false,
        reportsEnabled: false,
    });

    const [claimsRequestInFlight, setClaimsRequestInFlight] = useState(true);
    useEffect(() => {
        const makeRequest = async () => {
            try {
                const url =
                    process.env.REACT_APP_API_BASE_URL + '/staff/client-console-claims';
                const resp = await get(url);
                const { educatedInvestorEnabled, reportsEnabled } = resp.data;
                setClientConsoleAccess({
                    educatedInvestorEnabled: educatedInvestorEnabled ?? false,
                    reportsEnabled: reportsEnabled ?? false,
                });
                setClaimsRequestInFlight(false);
            } catch (error) {
                console.error(error);
                setErrorText(ERROR_TEXT.claims);
                setClaimsRequestInFlight(false);
            }
        };
        makeRequest();
    }, []);

    return (
        <MenuContext.Provider
            value={{
                selectedMenuItem,
                setSelectedMenuItem,
                isMenuOpen,
                toggleMenuOpen,
                isPresentationMode,
                togglePresentationMode,
                selectedButton,
                setSelectedButton,
                isWorldEventsMenuVisible,
                setIsWorldEventsMenuVisible,
                clientConsoleAccess,
                setClientConsoleAccess,
                errorText,
                claimsRequestInFlight,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
};
