import React, { useState, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import Axios from 'axios';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';

function SBBI30YearChart() {
    const { getAccessTokenSilently } = useAuth0();
    const instance = Axios.create();
    const axios = setupCache(instance, {
        storage: buildWebStorage(localStorage, 'axios-cache:')
    });
    const [data, setData] = useState([]);
    const [historicalEvents, setHistoricalEvents] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently();
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/ei/sbbi-thirty-year`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setData(await response.data);

                const responseEvents = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/ei/historical-events`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setHistoricalEvents(await responseEvents.data);
            } catch (e) {
                console.error(e);
            }
        })();
    }, [getAccessTokenSilently]);

    const annotations = useMemo(() => {
        const now = new Date();
        const thirtyYearsAgo = new Date(now.setFullYear(now.getFullYear() - 30));
        const BAND_RANGE = [3.8, 5.3];
        const BAND_INCREMENT = 0.14;
        let yBand = BAND_RANGE[0];
        return _(historicalEvents)
            .filter((e) => e.Date > thirtyYearsAgo.toISOString())
            .orderBy('Date')
            .map((e) => {
                yBand += BAND_INCREMENT;
                yBand = yBand > BAND_RANGE[1] ? BAND_RANGE[0] : yBand;
                return {
                    x: e.Date,
                    y: yBand,
                    text: e.Event,
                    showarrow: true,
                    arrowhead: 6,
                    ax: 4,
                    ay: 0,
                    xanchor: 'left',
                    font: { color: 'rgba(0, 0, 0, 0.6)', size: 10 },
                    arrowcolor: 'rgba(0, 0, 0, 0.6)',
                };
            })
            .value();
    }, [historicalEvents]);

    if (!data || data.length === 0) {
        return <div>Loading SBBI (all-time) chart...</div>;
    }

    let chartData = {};

    // Get list of metrics (excluding 'Date')
    const metrics = Object.keys(data[0]).filter((key) => key !== 'Date');

    // Iterate through each metric and create a trace object
    metrics.forEach((metric) => {
        if (metric === 'Balanced_Portfolio' || metric === 'Growth_Portfolio' || metric === 'Income_Portfolio') {
            return;
        }
        const trace = {
            name: calculateTraceName(metric),
            x: data.map((entry) => entry.Date),
            y: data.map((entry) => entry[metric]),
            type: 'scatter',
            yaxis: 'y1', // Adjust yaxis as per your requirement
        };

        chartData[metric] = trace;
    });

    // y-axis tick values and text
    const yAxisTicks = {
        tickValues: [1000, 10000, 100000, 400000],
        tickText: ['1,000', '10,000', '100,000', '400,000'],
    };

    const years = Array.from({ length: 31 }, (_, i) => 1994 + i);
    const xAxisTicks = {
        tickValues: years,
        tickText: years.map(year => `'${year.toString().slice(-2)}`)
    }

    return (
        <div style={{ width: '100%', minWidth: '300px' }}>
            <Plot
                divId='PlotlyGraph'
                data={Object.values(chartData).map((trace) => ({
                    x: trace.x,
                    y: trace.y,
                    name: trace.name,
                    type: 'scatter',
                    mode: 'lines',
                    line: {
                        color: getLineColor(trace.name),
                    },
                }))}
                layout={{
                    height: 700,
                    yaxis: {
                        // title: {
                        //     text: 'Growth of $100 (with no acquisition costs or taxes and all income reinvested)',
                        //     font: { size: 8 },
                        // },
                        type: 'log',
                        tickvals: yAxisTicks.tickValues,
                        ticktext: yAxisTicks.tickText,
                        range: [2.8, 5.8],
                    },
                    xaxis: {
                        showticklabels: true,
                        tickvals: xAxisTicks.tickValues,
                        ticktext: xAxisTicks.tickText,
                    },
                    margin: { r: 50, t: 25, b: 25, l: 80 },
                    showlegend: false,
                    annotations: null,
                }}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
                config={{
                    displayModeBar: false,
                }}
            />
        </div>
    );
}

const calculateTraceName = (metric) => {
    switch (metric) {
        case 'FOUSA05XQ2':
            return 'U.S. Small Stock Total Return Index';
        case 'FOUSA07Y2R':
            return 'U.S. Large Stock Total Return Index';
        case 'FOUSA05XQ4':
            return '20-Year Government Bonds';
        case 'FOUSA05XQ9':
            return '5-Year Government Bonds';
        case 'FOUSA05XTS':
            return '30-Day Treasury Bills';
        case 'XIUSA000RX':
            return 'World ex-U.S. Stocks';
        case 'CPIAUCNS':
            return 'Inflation';
        case 'FOUSA06R6S':
            return 'Gold'
        case 'XIUSA04F2P':
            return 'Emerging Markets Stocks'
        case 'Global_Portfolio':
            return 'Global Portfolio'
        default:
            return 'Other'
    }
}

//TODO: an API method to map from index name to common name and short name
function getLineColor(datasetName) {
    switch (datasetName) {
        case 'U.S. Small Stock Total Return Index':
        case 'FOUSA05XQ2':
            return '#1268B3';
        case 'U.S. Large Stock Total Return Index':
        case 'FOUSA07Y2R':
            return '#8CA2D3';
        case 'SP/TSX Composite': // not in the
            return '#AC1D37'; // current dataset
        case 'Balanced Portfolio':
        case 'Balanced_Portfolio':
            return '#F3C317';
        case '20-Year Government Bonds':
        case 'FOUSA05XQ4':
            return '#EE7623';
        case '5-Year Government Bonds':
        case 'FOUSA05XQ9':
            return '#F39B5D';
        case '30-Day Treasury Bills':
        case 'FOUSA05XTS':
            return '#658D3C';
        case 'Inflation\r':
        case 'CPIAUCNS':
            return '#808285';
        case 'Growth_Portfolio':
            return '#FFE045';
        case 'Income_Portfolio':
            return '#DBB015';
        case 'Global Portfolio':
            return '#F3C317';
        case 'XIUSA000RX': //World ex.-U.S. <=> MSCI World ex USA GR USD
        case 'FOUSA06R6S': //Gold <=> LBMA Gold Price AM USD
        case 'XIUSA04F2P': //Emerging Markets <=> MSCI EM GR USD
        default:
            return '#' + Math.random().toString(16);
    }
}

export default React.memo(SBBI30YearChart); // Memo to keep charts from redrawing when exporting image
