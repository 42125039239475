import React from 'react';
import morningstarLogo from '../../images/morningstar_logo_svg.svg';
import '../../css/Footer/Footer.css';

const Footer = () => {
	return (
		<div className="footer">
			<div className="disclaimer-section">
				<b>Past performance is no guarantee of future results.</b> Hypothetical value of $1 invested at the beginning of
				the time period indicated. Assumes reinvestment of income and no transaction costs or taxes. This is for illustrative
				purposes only and not indicative of any investment. An investment cannot be made directly in an index. Government
				bonds and Treasury bills are guaranteed by the full faith and credit of the United States government as to the timely
				payment of principal and interest, while stocks are not guaranteed and have been more volatile than the other asset
				classes. Furthermore, small stocks are more volatile than large stocks and are subject to significant price
				fluctuations, business risks, and are thinly traded. The balanced portfolio was created for illustrative purposes
				only. It is neither a recommendation, nor an actual portfolio. All income was reinvested and the portfolio was
				rebalanced monthly. Recession data is from the National Bureau of Economic Research (NBER). NBER does not define a
				recession in terms of two consecutive quarters of decline in real GDP. Rather, a recession is a recurring period of
				decline in total output, income, employment, and trade usually lasting from six months to a year and marked by
				widespread contractions in many sectors of the economy. Gold prices are from London Bullion Market Association
				and represent the London P.M. daily closing prices per troy ounce. Oil prices are for West Texas Intermediate
				Crude per barrel from Morningstar. Gold and oil prices quoted in U.S. dollars. Market expansions, contractions,
				and recoveries are defined for U.S. Large Stocks, which are representative of the stock market. A contraction
				is defined by a decline in the stock market from its peak by 10% or more. A recovery is represented as the number
				of months from the bottom of a contraction to its previous peak. An expansion measures the subsequent
				performance of the index from the recovery until it reaches the next peak level before another 10% decline.
				Returns are compound annual returns, and risk is measured by standard deviation. Standard deviation measures
				the fluctuation of returns around the arithmetic average return of the investment. The worst 5-year
				calculations are out of 1,117 rolling 60-month periods. Source: U.S. Small Stocks—Ibbotson® Small Company
				Stock Index; U.S. Large Stocks—Ibbotson® Large Company Stock Index; 20-year Government Bonds—Ibbotson® U.S.
				Long-Term Government Bond Index; 5-Year Government Bonds—Ibbotson® U.S. Intermediate-Term Government Bond
				Index; 30-Day Treasury Bills—30-day U.S. Treasury bill; Inflation—Consumer Price Index; Prime Interest
				Rate—The Federal Reserve; 30-Year Government Bond Yield—The Federal Reserve; S&P 500 Price Earnings
				Ratio—Robert Shiller (Yale) from 1926–1999 and Morningstar thereafter. Dividend Yield – Robert Shiller
				(Yale) from 1925 to 2023. Life expectancy data from The National Center for Health Statistics, National
				Vital Statistics Reports. Minimum wage data from the U.S. Department of Labor. Federal deficit data from
				usgovernmentspending.com. Federal tax rates from the Tax Foundation. Personal saving rate and unemployment
				data from the U.S. Bureau of Economic Analysis. Median house price and population statistics from the
				U.S. Census Bureau. <b>Additional disclosure: The Past 30 Years</b> —Hypothetical value of $10,000 invested at the
				beginning of 1994. The 1994 start date was selected to depict a retirement time horizon of 30 years. International
				investments involve special risks such as fluctuations in currency, foreign taxation, economic and political risks,
				liquidity risks, and differences in accounting and financial standards. Emerging-market investments are riskier than
				developed market investments. Gold, like any other coin or bullion, is subject to investment risks like perceived
				scarcity of coin, its quality, current demand, market sentiment, and economic factors. The global portfolio was
				created for illustrative purposes only. It is neither a recommendation, nor an actual portfolio. All income
				was reinvested and the portfolio was rebalanced monthly. Correlation data is based on monthly returns. Asset
				classes can be positively or negatively correlated, or have no correlation at all. Perfect positive correlation
				between two assets is represented by +1, while perfect negative correlation is represented by –1.
				Uncorrelated assets assume the value of 0. The worst 5-year calculations are out of 301 rolling 60-month periods.
				Source: World ex-U.S. Stocks—Morgan Stanley Capital International (MSCI) World ex-U.S. Index; Bonds— Ibbotson® U.S.
				Long-Term Government Bond Index; Emerging-Market Stocks—Morgan Stanley Capital International Emerging Markets
				Index; Gold—Federal Reserve (2nd London fix) through 1987 and Wall Street Journal London A.M. closing price
				thereafter. <b>Annual Net Asset Fund Flows:</b> U.S.-domiciled open-end fund flows from Morningstar. Start date of
				1994 constrained by data availability. U.S. stock: funds that primarily invest in U.S. stocks; International stock: funds
				that invest in specific regions or a diversified mix of international stocks with 40% or more in foreign stocks; Bond:
				taxable bond funds (government, corporate, international, emerging markets, high-yield, multisector) that invest primarily
				in fixed-income securities of varying maturities. This chart is presented for informational purposes only and is not
				financial advice. iGrad, LLC is not a financial advisor. If you want financial or other professional advice, then you
				should consult with a qualified professional. <b>The reproduction of part or all of this chart without prior written consent
				from iGrad, LLC is prohibited.</b>
			</div>
			<div className="morningstar-container">
				<img
					src={morningstarLogo}
					alt="Morningstar Logo"
					className="morningstar-logo"
				/>
			</div>
		</div>
	);
};

export default Footer;
