import React, { useState, useEffect } from 'react';
import sideMenuButtonCollapsed from '../../../src/images/customModebar/custom-modebar-open-close-button.svg';
import sideMenuButtonExpanded from '../../../src/images/customModebar/custom-modebar-open-close-button-collapsed.svg';
import CustomModebar from './CustomModebar.js';

const ModebarContainer = ({selectedTool, setSelectedTool}) => {
    let [modebarOn, setModebarOn] = useState(true);
    const plotlyGraphId = 'PlotlyGraph' // Might need to update late if multiple graphs are on screen	

    const toggleCustomModebar = () => {
        setModebarOn(!modebarOn);
        document.querySelector('.main-content').classList.toggle('expanded');

        // Keep focus on button when toggled for keyboard
        if(modebarOn){
            setTimeout(()=>{
                document.getElementById("modebar-collapsed").focus();
            }, 0)
        }else if(!modebarOn){
            setTimeout(()=>{
                document.getElementById("modebar-expanded").focus();
            }, 0)
        }
    }

    // Give plotly graph time to exist
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setModebarOn(true);
    //     }, 0);

    //     // Cleanup function to clear the timer if the component unmounts
    //     return () => clearTimeout(timer);
    // }, []);

    return (

        (modebarOn ? <div className='modebar-container-div' id='modebar-container-expanded'>
            <CustomModebar
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                className='custom-mode-bar-element'
                plotlyGraphId={plotlyGraphId} />
            <button className='unstyled-button modebar-toggle-btn' id='modebar-expanded' onClick={toggleCustomModebar}>
                <img
                    src={sideMenuButtonCollapsed}
                    className='modebar-menu-button-expanded'
                    alt='Side Menu Button'
                />
            </button>
        </div>
            :
        <div className='modebar-container-div-collapsed' id='modebar-container-collapsed'>
            <button className='unstyled-button modebar-toggle-btn' id='modebar-collapsed' onClick={toggleCustomModebar}>
                <img
                    src={sideMenuButtonExpanded}
                    className='modebar-menu-button-collapsed'
                    alt='Side Menu Button Collapsed'
                />
            </button>
        </div >)
    );

}
export default ModebarContainer;