import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserFeedback = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'feedbackDate', name: 'Date', dataType: 'date' },
        {
            field: 'feedbackType',
            name: 'Feedback',
            dataType: 'multiselect',
            styles: { minWidth: '20rem' },
        },
        { field: 'sentimentScore', name: 'Sentiment Score', dataType: 'numeric' },
        { field: 'resourceType', name: 'Resource', dataType: 'multiselect' },
        {
            field: 'resourceTitle',
            name: 'Title',
            dataType: 'multiselect',
            styles: { minWidth: '20rem' },
        },
        {
            field: 'freeFormFeedback',
            name: 'Additional Feedback',
            dataType: 'string',
            styles: { minWidth: '40rem' },
        },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.feedbackDate),
        {
            count: true,
        }
    );

    const chartData = [
        {
            name: 'Total Feedback',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.count),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Feedback Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserFeedback;
