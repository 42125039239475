import React, { useContext } from 'react';
import ChartOverlayWrapper from './ChartOverlayWrapper';
import { EconomicDataContext } from '../../context/EconomicDataContext';

function EconomicCyclesChart({selectedTool}) {
    const { 
            economicCycles,
            loadingEconomicCycles,
        } = useContext(EconomicDataContext);

    if (loadingEconomicCycles || economicCycles.length === 0) {
        return <div>Loading Economic Cycles chart...</div>;
    }

    const globalStartDate = economicCycles.reduce((min, current) => {
        const currentDate = new Date(current.StartDate);
        return currentDate < min ? currentDate : min;
    }, new Date('9999-12-31'));

    const minYear = globalStartDate.getFullYear();

    // Since return of a recovery is suspect, pin to the max
    // of non-recovery items.
    const maxReturn = Math.max(
        ...economicCycles
            .filter((item) => item.Phase !== 'Recovery')
            .map((item) => Math.abs(item.Return))
    );

    const expansions = economicCycles.filter(
        (item) => item.Phase === 'Expansion'
    );
    const downturns = economicCycles.filter((item) => item.Phase === 'Downturn');
    const recoveries = economicCycles.filter((item) => item.Phase === 'Recovery');

    const calculateXPositionInYears = (startDate, width) => {
        const localStartDate = new Date(startDate);
        const yearsFromGlobalStartDate =
            (localStartDate - globalStartDate) / (1000 * 60 * 60 * 24 * 365);
        const xPositionInYears = yearsFromGlobalStartDate + 0.5 * width;
        return xPositionInYears + minYear;
    };

    const calculateWidth = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const widthInYears = (end - start) / (1000 * 60 * 60 * 24 * 365);
        return widthInYears;
    };

    const calculateDurationInMonths = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const diffInMonths =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());

        return diffInMonths;
    };

    function floatToDate(floatNumber) {
        // Extract the year (integer part) and days fraction (decimal part)
        let year = Math.floor(floatNumber);
        let daysFraction = floatNumber - year;

        // Determine if it's a leap year
        let isLeapYear = new Date(year, 1, 29).getMonth() === 1;

        // Calculate total days in the year
        let totalDaysInYear = isLeapYear ? 366 : 365;

        // Calculate the day of the year
        let dayOfYear = Math.floor(daysFraction * totalDaysInYear) + 1; // +1 because dayOfYear is 1-based

        // Convert day of year to date format (day, month, year)
        let date = dayOfYearToDate(dayOfYear, year);

        return date;
    }

    function dayOfYearToDate(dayOfYear, year) {
        // Convert day of year to date format (day, month, year)
        let date = new Date(year, 0); // January 0th is actually December 31st of the previous year
        date.setDate(dayOfYear);
        return date;
    }

    const generateTraces = (phaseData, negative, color, legendStatus) => {
        const filteredPhaseData = phaseData;

        return filteredPhaseData.map((item) => {
            const phase = item.Phase;
            const showLegend = !legendStatus[phase];  // Show legend only once per phase
            legendStatus[phase] = true;

            const itemYValue = phase === 'Recovery'
                ? [negative === true ? -100 : 100]  // Set y to -100 or 100 based on negative
                : [
                    negative === true
                        ? Math.max(-100, Math.min(100, (-1 * 100 * item.Return) / maxReturn))
                        : Math.max(-100, Math.min(100, (100 * item.Return) / maxReturn))
                ];

            const itemYPercentage = parseFloat(itemYValue[0].toFixed(2));

            return {
                x: [
                    floatToDate(
                        calculateXPositionInYears(
                            item.StartDate,
                            calculateWidth(item.StartDate, item.EndDate)
                        )
                    )
                ],
                y: itemYValue,
                width: [
                    calculateWidth(item.StartDate, item.EndDate) * 365 * 24 * 60 * 60 * 1000
                ],
                marker: { color: color },
                name: phase,
                hoverinfo: 'text',
                showlegend: showLegend,
                hovertext: `<b>${phase}</b></br>Start Date: ${new Date(item.StartDate)
                    .toISOString()
                    .split('T')[0]}<br />End Date: ${new Date(item.EndDate)
                        .toISOString()
                        .split('T')[0]}<br />Duration (Months): ${calculateDurationInMonths(
                            item.StartDate,
                            item.EndDate
                        )}</br>Percentage: ${itemYPercentage}%`,
                type: 'bar',
                yaxis: 'y2',
                orientation: 'v',
                legend: 'legend2',
                legendgroup: phase, // Group all traces of the same phase so they all toggle from legend at once.
            };
        });
    };

    // Legend status to control showing legend once per phase
    const legendStatus = {};
    const economicCycleTraces = [
        ...generateTraces(expansions, false, 'rgba(123, 207, 235, 0.7)', legendStatus),
        ...generateTraces(downturns, false, 'rgba(0, 168, 225, 0.7)', legendStatus),
        ...generateTraces(recoveries, false, 'rgba(204, 238, 249, 0.7)', legendStatus),
        ...generateTraces(recoveries, true, 'rgba(204, 238, 249, 0.7)', legendStatus)
    ];

    // If we end up turning the y-axis for SBBI back on in ChartOverlayWrapper.
    // This and the tickvals, ticktext properties will just show 100% and (100%) like in the Figma
    const yAxisTicks = {
		tickValues: [
		  -100,
		  100
		],
		tickText: [
		  '(100%)',
		  '100%',
		]
	  };

    const yAxisSettings =
	{
		side: 'left',
        showgrid: false,
        fixedrange: true,
    }

    const xAxisSettings = null;

    return (
        <div style={{ width: '100%', minWidth: '300px' }}>
            <ChartOverlayWrapper singleChartData={economicCycleTraces} singleChartXAxisSettings={xAxisSettings} singleChartYAxisSettings={yAxisSettings} selectedTool={selectedTool} />
        </div>
    );
}

export default React.memo(EconomicCyclesChart); // Memo to keep charts from redrawing when exporting image
