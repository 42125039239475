import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserJournals = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'journalDate', name: 'Date', dataType: 'date' },
        { field: 'journalCategory', name: 'Category', dataType: 'string' },
        {
            field: 'journalPrompt',
            name: 'Prompt',
            dataType: 'string',
            styles: { minWidth: '30rem' },
        },
        { field: 'stressScore', name: 'Stress Score', dataType: 'numeric' },
        { field: 'stressFromDate', name: 'From Date', dataType: 'date' },
        { field: 'stressToDate', name: 'To Date', dataType: 'date' },
        { field: 'stressIsCurrent', name: 'Current', dataType: 'boolean' },
        { field: 'stressAppraisalScore', name: 'Appraisal', dataType: 'numeric' },
        { field: 'stressAvoidanceScore', name: 'Avoidance', dataType: 'numeric' },
        { field: 'stressBehavioralScore', name: 'Behavioral', dataType: 'numeric' },
        { field: 'stressCognitiveScore', name: 'Cognitive', dataType: 'numeric' },
        { field: 'stressEmotionalScore', name: 'Emotional', dataType: 'numeric' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.journalDate),
        {
            count: true,
        }
    );

    const chartData = [
        {
            name: 'Total Journals',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.count),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Journals Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserJournals;
