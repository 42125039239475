import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import ChartsTab from './ChartsTab';
import SettingsTab from './SettingsTab';
import PortfolioTab from './PortfolioTab';
import { useContext, useState, useEffect } from 'react';
import { ReactComponent as XButton } from '../../../images/x_icon.svg';
import { CustomizeContext } from '../../../context/CustomizeContext';

const CustomizePopup = () => {
  const {
    customizeSettings,
    setIsCustomizeMenuVisible,
    setActiveTab,
    setRebaseYear,
    getSbbiRebaseData,
    setActiveRebaseData,
    portfolioObjectNameToProperty,
    rebasedPortfolioNames,
  } = useContext(CustomizeContext);

  const [yearToValidate, setYearToValidate] = useState(customizeSettings.rebaseYear);
  const [errorMessage, setErrorMessage] = useState('');
  const MIN_YEAR = 1925;
  const MAX_YEAR = new Date().getFullYear();

  useEffect(() => {
    if (customizeSettings.activeRebase.length > 0 && customizeSettings.rebaseData.length > 0) {
      const activePortfolio = rebasedPortfolioNames.indexOf(customizeSettings.activePortfolioType.toLowerCase());
      const renamedPortfolio = renamePortfolioInData(customizeSettings.rebaseData[activePortfolio]);
      setActiveRebaseData(renamedPortfolio);
    }
  }, [customizeSettings.activePortfolioType])

  const renameKey = (data, oldKey, newKey) => {
    if (data) {
      return data.map(item => {
        // Destructure to remove the old key and add the new key
        const { [oldKey]: value, ...rest } = item;
        return { ...rest, [newKey]: value };
      });
    }
  };

  const renamePortfolioInData = (sbbiRebaseData, passedPortfolio = "") => {
    const activePortfolio = passedPortfolio || customizeSettings.activePortfolioType;
    let newPortfolioName = '';

    switch (activePortfolio) {
      case 'Balanced':
        newPortfolioName = 'Balanced_Portfolio';
        break;
      case 'Income':
        newPortfolioName = 'Income_Portfolio';
        break;
      case 'Growth':
        newPortfolioName = 'Growth_Portfolio';
        break;
      default:
        newPortfolioName = activePortfolio;
        break;
    }

    const renamedData = renameKey(sbbiRebaseData, 'PortfolioValue', newPortfolioName);
    return renamedData;
  }

  const rebase = async () => {
    const formattedPortfolioData = portfolioObjectNameToProperty(customizeSettings.portfolioData)
    let year = validateYear();
    if (!year) {
      year = customizeSettings.rebaseYear;
    }
    const sbbiRebaseData = await getSbbiRebaseData(
      year,
      customizeSettings.amountInvested,
      formattedPortfolioData,
    );

    const renamedSbbiData = renamePortfolioInData(sbbiRebaseData)

    setActiveRebaseData(renamedSbbiData);
  };

  const rebaseNewCustomPortfolio = async (updatedData, portfolioType) => {
    updatedData = Object.fromEntries(
      Object.entries(updatedData).filter(([key]) => !key.includes("Percentage"))
    );
    const formattedPortfolioData = portfolioObjectNameToProperty(updatedData)
    let year = validateYear();
    if (!year) {
      year = customizeSettings.rebaseYear;
    }
    const sbbiRebaseData = await getSbbiRebaseData(
      year,
      customizeSettings.amountInvested,
      formattedPortfolioData,
    );
    const renamedSbbiData = renamePortfolioInData(sbbiRebaseData, portfolioType)
    return renamedSbbiData;
  };

  const validateYear = () => {
    const year = Number(yearToValidate);
    if (year < MIN_YEAR || year > MAX_YEAR) {
      setErrorMessage(`Year must be between ${MIN_YEAR} and ${MAX_YEAR}.`);
    } else {
      setRebaseYear(year);
      return year;
    }
  }

  const renderTabContent = () => {
    switch (customizeSettings.activeTab) {
      case 'Settings':
        return <SettingsTab
          rebase={rebase}
          MIN_YEAR={MIN_YEAR}
          MAX_YEAR={MAX_YEAR}
          yearToValidate={yearToValidate}
          errorMessage={errorMessage}
          setYearToValidate={setYearToValidate}
        />
      case 'Charts':
        return <ChartsTab />;
      case 'Portfolio':
        return <PortfolioTab
          rebase={rebase}
          rebaseNewCustomPortfolio={rebaseNewCustomPortfolio}
          MIN_YEAR={MIN_YEAR}
          MAX_YEAR={MAX_YEAR}
          yearToValidate={yearToValidate}
          errorMessage={errorMessage}
          setYearToValidate={setYearToValidate}
        />;
      default:
        return null;
    }
  };

  return (
    <div className="customize-menu">
      <div className="customize-menu-header font-weight-600">
        <h3>Customize</h3>
        <button className="close-button" onClick={() => setIsCustomizeMenuVisible(false)}><XButton /></button>
      </div>
      <div className="customize-tabs">
        <button
          className={customizeSettings.activeTab === 'Settings' ? 'active' : ''}
          onClick={() => setActiveTab('Settings')}>
          Settings
        </button>
        <button
          className={customizeSettings.activeTab === 'Charts' ? 'active' : ''}
          onClick={() => setActiveTab('Charts')}>
          Charts
        </button>
        <button
          className={customizeSettings.activeTab === 'Portfolio' ? 'active' : ''}
          onClick={() => setActiveTab('Portfolio')}>
          Portfolio
        </button>
      </div>
      <div className="tab-content-container divider">
        {renderTabContent()}
      </div>
    </div>
  )
}
export default CustomizePopup