import { createContext, useState, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { capitalizeFirstLetter } from '../helpers';
import Axios from 'axios';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';

export const CustomizeContext = createContext();

export const CustomizeProvider = ({ children }) => {
    // Not doing much currently. Can cache portfolio data once we convert that to get
    const instance = Axios.create();
    const axios = setupCache(instance, {
        storage: buildWebStorage(localStorage, 'axios-cache:')
    });

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    let firstPortfolioType = '';
    const [portfolioDataInitial, setPortfolioDataInitial] = useState();
    const [loadingPortfoliosData, setLoadingPortfoliosData] = useState(true);
    const [rebasedPortfolioNames, setRebasedPortfolioNames] = useState([]);

    const [customizeSettings, setCustomizeSettings] = useState({
        'isCustomizeMenuVisible': false,
        'amountInvested': null,
        'chartTypes': null,
        'portfolioData': {},
        'activePortfolioType': null,
        'activeTab': null,
        'percentageState': null,
        'rebaseYear': null,
        'activeRebase': {},
        'rebaseData': {}
    });

    // Remove email and set to Get once we can get email in API
    const getPortfolioData = async () => {
        if (!isAuthenticated) {
            return {}; // Return an empty object if not authenticated
        }

        const token = await getAccessTokenSilently();
        const portfoliosResponse = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/ei/portfolios`,
            {
                email: user.email
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        const portfolioResp = await portfoliosResponse.data;

        const portfolioObjects = portfolioArrayNameToProperty(portfolioResp)

        return portfolioObjects;
    };

    const portfolioArrayNameToProperty = (portfoliosArray) => {
        return portfoliosArray.reduce((acc, portfolio) => {
            const { PortfolioType, ...rest } = portfolio;
            acc[PortfolioType] = { ...rest };
            return acc;
        }, {});
    }

    const portfolioObjectNameToProperty = (portfoliosObject) => {
        if (typeof portfoliosObject !== "object" || portfoliosObject === null || Array.isArray(portfoliosObject)) {
            console.error("portfolios is not a valid object:", portfoliosObject);
            return [];
        }

        return Object.entries(portfoliosObject).map(([key, value]) => {
            const { PortfolioType, ...rest } = value;
            return {
                ...rest,
                PortfolioType: PortfolioType || key,
            };
        });
    };

    const saveCustomPortfolio = async (portfolio) => {
        if (!isAuthenticated) {
            return {};
        }
        try {
            const token = await getAccessTokenSilently();

            const addedPortfolio = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/ei/portfolios/add`,
                {
                    email: user.email,
                    PortfolioType: portfolio.name,
                    SmallStocks: portfolio.SmallStocks || 0,
                    LargeStocks: portfolio.LargeStocks || 0,
                    Bonds: portfolio.Bonds || 0,
                    Cash: portfolio.Cash || 0,
                    Other: portfolio.Other || 0
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (addedPortfolio.status !== 200) {
                throw new Error("Error adding portfolio.");
            }

            const newPortfolio = addedPortfolio.data;

            setCustomizeSettings((prev) => ({
                ...prev,
                portfolioData: {
                    ...prev.portfolioData,
                    [newPortfolio.PortfolioType]: {
                        SmallStocks: newPortfolio.SmallStocks || 0,
                        LargeStocks: newPortfolio.LargeStocks || 0,
                        Bonds: newPortfolio.Bonds || 0,
                        Cash: newPortfolio.Cash || 0,
                        Other: newPortfolio.Other || 0
                    }
                }
            }));

            return newPortfolio;
        } catch (error) {
            throw error;
        }
    };

    const getSbbiRebaseData = async (rebaseYear, initialInvestment, portfolios) => {
        if (!isAuthenticated) {
            return {}; // Return an empty object if not authenticated
        }

        const token = await getAccessTokenSilently();
        const rebaseResponse = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/ei/rebase-sbbi`,
            {
                rebaseYear: rebaseYear,
                initialInvestment: initialInvestment,
                portfolios: portfolios
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        const response = await rebaseResponse.data;
        const rebaseDataPortfolioNames = getRebaseIndexNames(response);
        setRebasedPortfolioNames(rebaseDataPortfolioNames);

        const activePortfolioToReturn = rebaseDataPortfolioNames.indexOf(customizeSettings.activePortfolioType.toLowerCase());

        const activeRebase = response[activePortfolioToReturn];
        setRebaseData(response);

        return activeRebase;
    };

    const getRebaseIndexNames = (rebaseData) => {
        if (!Array.isArray(rebaseData)) {
            console.error("Invalid input: rebaseData must be an array of arrays.");
            return [];
        }

        return rebaseData
            .map((innerArray) => {
                if (Array.isArray(innerArray) && innerArray.length > 0) {
                    const firstElement = innerArray[0];
                    return firstElement.PortfolioName || null;
                }
                return null;
            })
            .filter((name) => name !== null);
    };

    useEffect(() => {
        const fetchCustomizeSettings = async () => {
        const portfolios = await getPortfolioData()

        const amountInvested = getAmountInvested();
        const chartTypes = getChartTypes();
        const portfolioData = portfolios
        const activeTab = 'Settings';
        const rebaseYear = 1925;
        const activeRebase = {};
        const rebaseData = {};

        if (portfolioData && Object.keys(portfolioData).length > 0) {
            firstPortfolioType = Object.keys(portfolioData)[0].charAt(0).toUpperCase() + Object.keys(portfolioData)[0].slice(1);
        }

        setCustomizeSettings((prev) => ({
            ...prev,
            'chartTypes': chartTypes,
            'activePortfolioType': capitalizeFirstLetter(firstPortfolioType),
            'portfolioData': portfolioData,
            'amountInvested': amountInvested,
            'activeTab': activeTab,
            'percentageState': 'good',
            'rebaseYear': rebaseYear,
            'activeRebase': activeRebase,
            'rebaseData': rebaseData,
        }));

        setLoadingPortfoliosData(false);
    }
    fetchCustomizeSettings()
    }, []);

    const setIsCustomizeMenuVisible = (isVisible) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'isCustomizeMenuVisible': isVisible,
        }));
    };

    const setAmountInvested = (newAmountInvested) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'amountInvested': newAmountInvested,
        }));
    };

    const setActivePortfolioType = (portfolioType) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'activePortfolioType': capitalizeFirstLetter(portfolioType),
        }));
    };

    const setPortfolioData = (newPortfolioData) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'portfolioData': {
                ...prev.portfolioData,
                [prev.activePortfolioType.toLowerCase()]: newPortfolioData
            }
        }));
    };

    const setPercentageState = (state) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'percentageState': state,
        }));
    };

    const setChartTypes = (chartTypes) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'chartTypes': chartTypes,
        }));
    };

    const setActiveTab = (tab) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'activeTab': tab,
        }));
    };

    const setRebaseYear = (year) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'rebaseYear': year,
        }));
    };

    const setActiveRebaseData = (activeRebase) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'activeRebase': activeRebase,
        }));
    };

    const setRebaseData = (rebaseData) => {
        setCustomizeSettings((prev) => ({
            ...prev,
            'rebaseData': rebaseData,
        }));
    };

    const getAmountInvested = () => {
        return 1;
    }

    const getActivePortfolio = () => {
        return customizeSettings.portfolioData[customizeSettings.activePortfolioType.toLowerCase()];
    }

    const getChartTypes = () => {
        return {
            All: true,
            SBBI: true,
            Expansions: true,
            Commodities: true,
            InterestVsInflation: true,
            TaxRate: true,
            Government: true,
            SP500: true,
            Recessions: true,
        }
    }

    const contextValue = useMemo(() => ({
        customizeSettings,
        setIsCustomizeMenuVisible,
        setActivePortfolioType,
        setAmountInvested,
        setActiveTab,
        setChartTypes,
        setPercentageState,
        setPortfolioData,
        saveCustomPortfolio,
        setRebaseYear,
        getSbbiRebaseData,
        setActiveRebaseData,
        setRebaseData,
        loadingPortfoliosData,
        portfolioObjectNameToProperty,
        getActivePortfolio,
        rebasedPortfolioNames,
    }), [
        customizeSettings,
        setIsCustomizeMenuVisible,
        setActivePortfolioType,
        setAmountInvested,
        setActiveTab,
        setChartTypes,
        setPercentageState,
        setPortfolioData,
        saveCustomPortfolio,
        setRebaseYear,
        getSbbiRebaseData,
        setActiveRebaseData,
        setRebaseData,
        loadingPortfoliosData,
        portfolioObjectNameToProperty,
        getActivePortfolio,
        rebasedPortfolioNames,
    ]);

    return (
        <CustomizeContext.Provider
            value={contextValue}
        >
            {children}
        </CustomizeContext.Provider>
    );
}