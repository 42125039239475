import React, { useContext } from 'react';
import './App.css';
import SideMenu from './components/SideMenus/SideMenu';
import { useAuth0 } from '@auth0/auth0-react';
import { debounce } from './helpers';
import { MenuContext } from './context/MenuContext';
import { ThemeContext } from './context/ThemeContext';
import DisplayPane from './components/DisplayPane/DisplayPane';
import { Button } from '@mui/material';

function App() {
    const { isPresentationMode } = useContext(MenuContext);
    const { colors } = useContext(ThemeContext);

    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    React.useEffect(() => {
        window.addEventListener('resize', debounce(handleResize), false);
    }, []);

    React.useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty("--A1", `${colors.A1}`);
        root.style.setProperty("--A2", `${colors.A2}`);
        root.style.setProperty("--None", 'none');
    }, [colors]);

    const { isAuthenticated, isLoading: authLoading, loginWithRedirect } = useAuth0();

    if (authLoading) {
        return <div className="loading-indicator">Completing log-in. Please wait...</div>;
    }

    if (!isAuthenticated) {
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                <div className="flex justify-center my-4">
                    <h1 className="text-2xl font-bold text-primaryGreen">
                        Client Console
                    </h1>
                </div>
                <div>
                    <Button onClick={() => loginWithRedirect()} variant="contained">
                        Log In
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="App">
            <div className="layout-container">
                <div className="sidemenu-wrapper print:!hidden">
                    {!isPresentationMode && (
                        <div className="sidemenu-container">
                            <SideMenu />
                        </div>
                    )}
                </div>
                <DisplayPane dimensions={dimensions} />
            </div>
        </div>
    );
}

export default App;
