import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const CobrandAnalytics = () => {
    const { reportData } = useContext(ReportContext);

    const reportDataCustomized = reportData?.length
        ? reportData.map((x) => ({
              ...x,
              averageSessionDuration: parseFloat(
                  (x.averageSessionDuration / 60).toFixed(2)
              ),
          }))
        : [];

    const columns = [
        { field: 'sessionDate', name: 'Session Date', dataType: 'date' },
        { field: 'totalUsers', name: 'Total Users', dataType: 'numeric' },
        { field: 'newUsers', name: 'New Users', dataType: 'numeric' },
        { field: 'sessions', name: 'Sessions', dataType: 'numeric' },
        {
            field: 'averageSessionDuration',
            name: 'Avg Session Duration (minutes)',
            dataType: 'numeric',
        },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.sessionDate),
        {
            sumFields: ['totalUsers', 'newUsers', 'sessions', 'averageSessionDuration'],
        }
    );

    const chartDataTotalUsers = [
        {
            name: 'Total Users',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.totalUsersSum),
        },
    ];
    const chartDataNewUsers = [
        {
            name: 'Total Users',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.newUsersSum),
        },
    ];

    const chartDataSessions = [
        {
            name: 'Total Sessions',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.totalUsersSum),
        },
    ];
    const chartDataDuration = [
        {
            name: 'Average Sessions Duration',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.averageSessionDurationSum),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportDataCustomized}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartDataTotalUsers}
                    chartTitle="Total Users Over Time"
                />
                <ReportLineChart
                    data={chartDataNewUsers}
                    chartTitle="New Users Over Time"
                />
                <ReportLineChart
                    data={chartDataSessions}
                    chartTitle="Sessions Over Time"
                />
                <ReportLineChart
                    data={chartDataDuration}
                    chartTitle="Average Session Duration Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default CobrandAnalytics;
