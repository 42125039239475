import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserStressChange = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'evaluationDate', name: 'Evaluation Date', dataType: 'date' },
        { field: 'cognitiveScore', name: 'Cognitive Score', dataType: 'numeric' },
        { field: 'behavioralScore', name: 'Behavioral Score', dataType: 'numeric' },
        { field: 'emotionalScore', name: 'Emotional Score', dataType: 'numeric' },
        { field: 'avoidanceScore', name: 'Avoidance Score', dataType: 'numeric' },
        { field: 'appraisalScore', name: 'Appraisal Score', dataType: 'numeric' },
        { field: 'stressScore', name: 'Stress Score', dataType: 'numeric' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.evaluationDate),
        {
            count: true,
            avgFields: ['stressScore'],
        }
    );

    const chartData = [
        {
            name: 'Total Evaluations',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.count),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Stress Evaluations Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserStressChange;
