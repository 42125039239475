import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { formatDateToIsoExtDate } from '../../../helpers';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserXP = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'xpDate', name: 'Experience Date', dataType: 'date' },
        { field: 'xp', name: 'Points', dataType: 'numeric' },
        { field: 'xpCategory', name: 'Category', dataType: 'multiselect' },
        { field: 'stressScore', name: 'Stress Score', dataType: 'numeric' },
        { field: 'stressFromDate', name: 'From Date', dataType: 'date' },
        { field: 'stressToDate', name: 'To Date', dataType: 'date' },
        { field: 'stressIsCurrent', name: 'Current', dataType: 'boolean' },
        { field: 'stressAppraisalScore', name: 'Appraisal', dataType: 'numeric' },
        { field: 'stressAvoidanceScore', name: 'Avoidance', dataType: 'numeric' },
        { field: 'stressBehavioralScore', name: 'Behavioral', dataType: 'numeric' },
        { field: 'stressCognitiveScore', name: 'Cognitive', dataType: 'numeric' },
        { field: 'stressEmotionalScore', name: 'Emotional', dataType: 'numeric' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.xpDate),
        {
            sumFields: ['xp'],
        }
    );

    const chartData = [
        {
            name: 'XP Earned',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.xpSum),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart data={chartData} chartTitle="XP Earned Over Time" />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserXP;
