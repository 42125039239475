import { useContext } from 'react';
import { ReactComponent as DownloadIcon } from '../../../images/download-icon.svg';
import html2canvas from 'html2canvas';
import pptxgen from 'pptxgenjs';
import { jsPDF } from "jspdf";
import { exportToSVG } from '../../../helpers';
import { MenuContext } from '../../../context/MenuContext';
import { EI_PAGES } from '../../../constants';

const SaveViewMenu = ({ pageContentRef, setIsLoading, setIsExportViewMenuOpen, plotRef }) => {

    const {
        selectedMenuItem,
        selectedButton,
    } = useContext(MenuContext);

    const exportChart = (exportType) => {
        if (pageContentRef.current) {
            setIsLoading(true);
            // Hide menu so it isnt in screenshot
            setIsExportViewMenuOpen(false);
            setTimeout(() => {
                switch (exportType) {
                    case "PNG":
                        html2canvas(pageContentRef.current)
                            .then((canvas) => {
                                const link = document.createElement('a');
                                link.href = canvas.toDataURL('image/png');
                                link.download = 'chart.png';
                                link.click();
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.error('html2canvas error:', error);
                                setIsLoading(false);
                            });
                        break;
                    case "JPG":
                        html2canvas(pageContentRef.current)
                            .then((canvas) => {
                                const link = document.createElement('a');
                                link.href = canvas.toDataURL('image/jpg');
                                link.download = 'chart.jpg';
                                link.click();
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.error('html2canvas error:', error);
                                setIsLoading(false);
                            });
                        break;
                    case "PPT":
                        html2canvas(pageContentRef.current)
                            .then((canvas) => {
                                const imgDataUrl = canvas.toDataURL('image/jpg');

                                const pres = new pptxgen();
                                const slide = pres.addSlide();

                                const dpi = 96; // Standard screen DPI
                                const widthPixels = canvas.width;
                                const heightPixels = canvas.height;

                                const widthInInches = widthPixels / dpi;
                                const heightInInches = heightPixels / dpi;

                                slide.addImage({
                                    data: imgDataUrl,
                                    x: 0, // Offset from top and left (in)
                                    y: 0,
                                    w: widthInInches, // Image width and height (in)
                                    h: heightInInches
                                });

                                pres.writeFile({ fileName: 'chart.pptx' })
                                    .then(() => {
                                        console.log('Presentation created successfully');
                                        setIsLoading(false);
                                    })
                                    .catch((error) => {
                                        console.error('pptxgenjs error:', error);
                                        setIsLoading(false);
                                    });
                            })
                            .catch((error) => {
                                console.error('html2canvas error:', error);
                                setIsLoading(false);
                            });
                        break;
                    case "SVG":
                        html2canvas(pageContentRef.current)
                            .then((canvas) => {
                                const plotId = 'PlotlyGraph';
                                const canvasWidth = canvas.width;
                                const canvasHeight = canvas.height;
                                exportToSVG(plotId, canvasWidth, canvasHeight);
                                setIsLoading(false);
                            })
                        break;
                    case "PDF":
                        html2canvas(pageContentRef.current)
                            .then((canvas) => {
                                const link = document.createElement('a');
                                link.href = canvas.toDataURL('image/jpg');

                                const pdf = new jsPDF({
                                    orientation: selectedButton === 'Full Andex' ? 'portait': 'landscape',
                                    unit: "px",
                                    format: [canvas.width, canvas.height],
                                });
                                const pdfWidth = pdf.internal.pageSize.getWidth();
                                const pdfHeight = pdf.internal.pageSize.getHeight();
                                pdf.addImage(link.href, "PNG", 0, 0, pdfWidth, pdfHeight);
                                pdf.save("chart.pdf");
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.error('html2canvas error:', error);
                                setIsLoading(false);
                            });
                        break;
                }
            }, 0);
        } else {
            console.error('pageContentRef.current is null or undefined');
        }
    };

    return (
        <div className="export-menu">
            <div className="save-view">
                <strong>Save Current View</strong>
            </div>
            <button className="unstyled-button export-menu-item"
                onClick={() => exportChart("PPT")}>
                <span className="icon"><DownloadIcon /></span> PPT
            </button>
            <button className="unstyled-button export-menu-item"
                onClick={() => exportChart("PDF")}>
                <span className="icon"><DownloadIcon /></span> PDF
            </button>
            {selectedMenuItem.id === EI_PAGES.fullAndex && (
                <button className="unstyled-button export-menu-item"
                    onClick={() => exportChart("SVG")}>
                    <span className="icon"><DownloadIcon /></span> SVG
                </button>
            )}
            <button className="unstyled-button export-menu-item"
                onClick={() => exportChart("JPG")}>
                <span className="icon"><DownloadIcon /></span> JPG
            </button>
            <button className="unstyled-button export-menu-item"
                onClick={() => exportChart("PNG")}>
                <span className="icon"><DownloadIcon /></span> PNG
            </button>
        </div>
    );
};

export default SaveViewMenu;
