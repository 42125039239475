import React, { useContext, useState } from 'react';
import chartArrowIcon from '../../images/sideMenu/arrow_drop_down.svg';
import { MenuContext } from '../../context/MenuContext';
import { PAGE_TYPE } from '../../constants';
import { ReportContext } from '../../context/ReportContext';

const MenuBlock = ({ title, titleIcon, items, rootClass }) => {
    const [submenuVisible, setSubmenuVisible] = useState(true);
    const { selectedMenuItem, setSelectedMenuItem, isMenuOpen } = useContext(MenuContext);
    const { setSelectedReportId } = useContext(ReportContext);

    const minWidth = { minWidth: isMenuOpen ? '225px' : '40px' };

    // Sorry for this ugliness - will go away once the current page SOT is a proper router
    const handleClick = (item) => {
        setSelectedMenuItem(item);
        if (item.pageType === PAGE_TYPE.reports) {
            setSelectedReportId(item.id);
        }
    };

    return (
        <div className={rootClass} style={minWidth}>
            <div
                className="menu-title-container"
                onClick={() => setSubmenuVisible((x) => !x)}
            >
                {titleIcon}
                {isMenuOpen && (
                    <>
                        <p className="menu-title">{title}</p>
                        <img
                            src={chartArrowIcon}
                            alt="Arrow"
                            className={`arrow-icon ${submenuVisible ? 'expanded' : ''}`}
                        />
                    </>
                )}
            </div>
            <div className={`submenu-container ${submenuVisible ? 'expanded' : ''}`}>
                {items.map((item) => (
                    <div key={item.id}>
                        <div
                            className="menu-item-container"
                            onClick={() => handleClick(item)}
                        >
                            {item.icon}
                            {isMenuOpen && (
                                <p
                                    className={`ml-2 menu-item ${
                                        selectedMenuItem.id === item.id ? 'selected' : ''
                                    }`}
                                >
                                    {item.name}
                                </p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MenuBlock;
