const cellStyle = (colIndex) => ({
    padding: `2px ${colIndex === 0 ? 30 : 12}px 2px 12px`,
    textAlign: colIndex === 0 ? 'left' : 'center',
    fontWeight: '500',
});

const headerStyles = (colIndex) => ({
    ...cellStyle(colIndex),
    borderBottom: colIndex === 0 ? 'unset' : '2px black solid',
    verticalAlign: 'bottom',
    width: colIndex === 0 ? '20em' : 'unset',
});

const bodyStyles = (colIndex, value) => ({
    ...cellStyle(colIndex),
    color: value < 0 ? 'red' : 'unset',
    width: colIndex === 0 ? '20em' : 'unset',
});


const StandardTable = ({ columns, rows }) => {
    return (
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
                <tr>
                    {columns.map((col, colIndex) => (
                        <th key={col.name} style={headerStyles(colIndex)}>
                            {col.title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((col, colIndex) => (
                            <td
                                key={col.name}
                                style={bodyStyles(colIndex, row[col.name])}
                            >
                                {row[col.name]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default StandardTable;
