import React, { useEffect, useContext } from 'react';
import ChartOverlayWrapper from './ChartOverlayWrapper';
import { EconomicDataContext } from '../../context/EconomicDataContext';

const CommoditiesChart = ({selectedTool}) => {
	const {
		commodities,
		loadingCommodities,
		zoomedDates,
		setZoomedDates,
	} = useContext(EconomicDataContext);

	useEffect(() => {
		if(!zoomedDates.length > 0) { setZoomedDates([new Date('1986-01-01').getTime(), new Date().getTime()]); }
	}, [zoomedDates])

	if (loadingCommodities || commodities.length === 0) {
		return <div>Loading Commodities chart...</div>;
	}

	const trace1 = {
		name: 'Oil',
		x: commodities.map((e) => e.Date),
		y: commodities.map((e) => e.Oil),
		type: 'scatter',
		marker: {
			color: '#414042'
		},
		yaxis: 'y2',
	};

	const trace2 = {
		name: 'Gold',
		x: commodities.map((e) => e.Date),
		y: commodities.map((e) => e.Gold),
		type: 'scatter',
		marker: {
			color: '#F3C317'
		},
		yaxis: 'y3',
	};

	const xAxisSettings = {
		type: 'date',
		range: zoomedDates.length > 0 ? zoomedDates : ['1986-01-01', '2024-06-01'],
		dtick: 'M60', // Display every 10 years
		tickmode: 'linear',
		showgrid: false,
		side: 'bottom',
		showticklabels: true,
	};

	const yAxisSettings =
	{
		title: {
			text: 'Oil Price ($/barrel)',
			font: {
				size: 18,
				color: '#414042',
				weight: 'normal'
			}
		},
		tickfont: { color: 'black' },
		fixedrange: true,
	};

	const yAxis2Settings =
	{
		title: {
			text: 'Gold Price ($/ounce)',
			font: {
				size: 18,
				color: '#414042',
				weight: 'bold'
			}
		},
		tickfont: { color: 'gold', weight: 'bold' },
		overlaying: 'y2',
		side: 'right',
		fixedrange: true,
	};

	const chartData = [
		trace1,
		trace2
	];

	return (
		<div style={{ width: '100%', minWidth: '300px' }}>
			<ChartOverlayWrapper
				singleChartData={chartData}
				singleChartXAxisSettings={xAxisSettings}
				singleChartYAxisSettings={yAxisSettings}
				singleChartYAxis2Settings={yAxis2Settings}
				selectedTool={selectedTool}
			/>
		</div>
	);
};

export default React.memo(CommoditiesChart); // Memo to keep charts from redrawing when exporting image
