import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { formatDateToIsoExtDate } from '../../../helpers';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserLogins = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'loginDate', name: 'Login', dataType: 'date' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.loginDate),
        {
            count: true,
            countDistinctFields: ['username'],
        }
    );

    const chartData = [
        {
            name: 'Total Logins',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.count),
        },
        {
            name: 'Unique Logins',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.usernameCountDistinct),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart data={chartData} chartTitle="User Logins Over Time" />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserLogins;
