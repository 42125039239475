import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import { useContext } from 'react';
import { ReactComponent as PlusIcon } from '../../../images/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../../images/minus-icon.svg';
import { CustomizeContext } from '../../../context/CustomizeContext';

const SettingsTab = ({rebase, MIN_YEAR, yearToValidate, errorMessage, setYearToValidate}) => {
    const {
        customizeSettings,
        setAmountInvested,
        setRebaseYear,
        setActiveRebaseData,
    } = useContext(CustomizeContext);

    const MAX_AMOUNT_INVESTED = 10000000;

    const incrementInvestment = () => {
        if (customizeSettings.amountInvested + 10 > MAX_AMOUNT_INVESTED) {
            setAmountInvested(MAX_AMOUNT_INVESTED);
        } else {
            setAmountInvested(customizeSettings.amountInvested + 10);
        }
    };

    const decrementInvestment = () => {
        if (customizeSettings.amountInvested > 1) {
            setAmountInvested(customizeSettings.amountInvested - 10);
        }
    };

    const clearSettings = () => {
        setAmountInvested(1);
        setRebaseYear(MIN_YEAR);
        setYearToValidate(MIN_YEAR);
        setActiveRebaseData([]);
    };

    const handleAmountInvestedChange = (e) => {
        const value = e.target.value.replace(/[^\d]/g, '');
        const numberValue = Number(value);
        if (numberValue > MAX_AMOUNT_INVESTED) {
            setAmountInvested(MAX_AMOUNT_INVESTED);
        } else if (numberValue >= 1) {
            setAmountInvested(numberValue);
        } else {
            setAmountInvested(1);
        }
    };

    const handleRebaseYearChange = (e) => {
        const value = e.target.value.replace(/[^\d]/g, ''); // Allow only numbers
        setYearToValidate(value); // Temporarily set the raw value
    };

    return (
        <div className="tab-content settings-tab-content font-weight-400">
            <div>
                <label htmlFor='rebaseYearInput'>Rebase year</label>
                <div className="rebase-year number-input-div">
                    <input
                        type='text'
                        id='rebaseYearInput'
                        value={`${yearToValidate}`}
                        name='rebaseYear'
                        onChange={handleRebaseYearChange}
                    />
                </div>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <label htmlFor='settingsAmountInvestedInput'>Amount invested</label>
            <div className="amount-invested number-input-div">
                <button className='decrement' onClick={decrementInvestment}><MinusIcon /></button>
                <input
                    type='text'
                    id='settingsAmountInvestedInput'
                    value={`$${customizeSettings.amountInvested}`}
                    name='amountInvested'
                    onChange={handleAmountInvestedChange}
                />
                <button className='increment' onClick={incrementInvestment}><PlusIcon /></button>
            </div>
            <button
                className="apply-button customization-full-width font-weight-600"
                onClick={rebase}
            >
                Apply Settings
            </button>
            <div className='button-container'>
                <button
                    className="clear-button customization-half-width font-weight-600"
                    onClick={clearSettings}
                >
                    Clear Settings
                </button>
            </div>
        </div>
    );
};

export default SettingsTab;
