import { useState, useContext } from 'react';
import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import { ReactComponent as ChevronIcon } from '../../../images/chevron-icon.svg';
import { CustomizeContext } from '../../../context/CustomizeContext';
import { capitalizeFirstLetter } from '../../../helpers';

const PortfolioDropdown = () => {
  const {
    customizeSettings,
    setActivePortfolioType
  } = useContext(CustomizeContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setActivePortfolioType(option);
    setIsOpen(false);
  };

  const handleKeyDown = (event, option) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleOptionSelect(option);
    }
  };

  return (
    <div className="portfolio-dropdown-container">
      <label htmlFor="portfolioType">Portfolio type</label>
      <div className="dropdown">
        <button
          id='portfolioType'
          className="dropdown-header unstyled-button" 
          onClick={handleDropdownClick}
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {customizeSettings.activePortfolioType}
          <span className={`chevron ${isOpen ? 'rotate' : ''}`}><ChevronIcon /></span>
        </button>
        {isOpen && (
          <ul className="dropdown-options">
            {Object.keys(customizeSettings.portfolioData).map((option) => (
              option !== 'global' ? 
              <li
                role='button'
                tabIndex={0}
                key={option}
                className={`dropdown-option ${customizeSettings.activePortfolioType === option ? 'selected' : ''
                  }`}
                onClick={() => handleOptionSelect(option)}
                onKeyDown={(event) => handleKeyDown(event, option)}
                aria-selected={customizeSettings.activePortfolioType === option}
              >
                <span className="option-text">{capitalizeFirstLetter(option)}</span>
                <span className={`option-icon ${customizeSettings.activePortfolioType === capitalizeFirstLetter(option) ? 'filled' : 'outlined'}`}></span>
              </li> : null
            ))}

          </ul>
        )}
      </div>
    </div>
  );
};

export default PortfolioDropdown;
