// Prime DataTable is _interesting_. It does not respect a react component
// other than a Column being passed to them. In fact, it will take ANY component
// passed to it and simply assume it's a <Column> (Seriously, go ahead and pass
// a <Button> to it and see, kind of wild). This means we cant leverage
// a react wrapper in the traditional sense but we can accomplish the same goal
// by building default column props and passing them to the Column component

// As an alternative we could build something that utilizes a reflection type
// process to dynamically pull apart and reassemble a column wrapper into
// a valid Column component. I dont think thats worth it for this project.

import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

//////// String
export const stringColumnBuilder = (field, header, styles, customColumnProps) => {
    if (!field || !header) {
        throw new Error('Missing props in columnBuilder');
    }

    return (
        <Column
            key={field}
            field={field}
            header={header}
            filter
            filterPlaceholder={`Search by ${header}`}
            sortable
            bodyStyle={{ whiteSpace: 'normal', ...styles }}
            {...customColumnProps}
        />
    );
};

//////// Date
export const dateColumnBuilder = (field, header, styles, customColumnProps) => {
    if (!field || !header) {
        throw new Error('Missing props in columnBuilder');
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const bodyTemplate = (rowData) => {
        return rowData[field] ? formatDate(rowData[field]) : '';
    };

    const filterTemplate = (options) => (
        <Calendar
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
            mask="99/99/9999"
        />
    );

    return (
        <Column
            key={field}
            field={field}
            header={header}
            dataType="date"
            body={bodyTemplate}
            filter
            filterElement={filterTemplate}
            filterField={field}
            sortable
            bodyStyle={styles}
            {...customColumnProps}
        />
    );
};

//////// MultiSelect
export const multiSelectColumnBuilder = (
    field,
    header,
    allOptions,
    styles,
    customColumnProps
) => {
    if (!field || !header || !allOptions) {
        throw new Error('Missing props in columnBuilder');
    }

    const bodyTemplate = (rowData) => <span>{rowData[field]}</span>;
    const itemTemplate = (itemName) => <span>{itemName}</span>;

    const filterTemplate = (options) => (
        <MultiSelect
            value={options.value}
            options={allOptions}
            itemTemplate={itemTemplate}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Any"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: '14rem' }}
        />
    );

    return (
        <Column
            key={field}
            field={field}
            header={header}
            body={bodyTemplate}
            filter
            filterElement={filterTemplate}
            showFilterMatchModes={false} // Hides match mode selector (starts with, contains, etc)
            showAddButton={false} // Hides the filter "Add Rule" button
            showFilterMenuOptions={false} // Hides the match all / match any option
            sortable
            bodyStyle={styles}
            {...customColumnProps}
        />
    );
};

//////// Number
export const numericColumnBuilder = (field, header, styles, customColumnProps) => {
    if (!field || !header) {
        throw new Error('Missing props in columnBuilder');
    }

    return (
        <Column
            key={field}
            field={field}
            header={header}
            dataType="numeric"
            filter
            filterPlaceholder={`Search by ${header}`}
            sortable
            bodyStyle={styles}
            {...customColumnProps}
        />
    );
};

//////// Boolean
export const booleanColumnBuilder = (field, header, styles, customColumnProps) => {
    if (!field || !header) {
        throw new Error('Missing props in columnBuilder');
    }

    return (
        <Column
            key={field}
            field={field}
            header={header}
            dataType="boolean"
            filter
            filterPlaceholder={`Search by ${header}`}
            sortable
            bodyStyle={styles}
            {...customColumnProps}
        />
    );
};
