import React, { useState, useEffect, useMemo, useContext } from 'react';
import Plot from 'react-plotly.js';
import _, { set } from 'lodash';
import { EventContext } from '../../context/EventContext';
import { CustomizeContext } from '../../context/CustomizeContext';
import { EconomicDataContext } from '../../context/EconomicDataContext';
import {
    dollarsWithCommas,
    getSbbiTraceColor,
    getSbbiTraceCommonName,
    calculateContinuousCompoundingAPR,
    selectDragmode,
} from '../../helpers';

function FullAndexChart({ selectedTool }) {
    const { checkedItems } = useContext(EventContext);
    const [recessionsLegendOn, setRecessionsLegendOn] = useState(true);
    const {
        sbbi,
        loadingSbbi,
        historicalEvents,
        loadingEvents,
        recessions,
        loadingRecessions,
        economicCycles,
        loadingEconomicCycles,
        commodities,
        loadingCommodities,
        interestRateInflationData,
        loadingInterestRateInflationData,
        sP500PEData,
        loadingSP500Data,
        governmentData,
        loadingGovernmentData,
        zoomedChartData,
        zoomedDates,
    } = useContext(EconomicDataContext);

    // Toggle on off charts (hide the data if customize option is off)
    const {
        customizeSettings,
        loadingPortfoliosData,
        setActiveRebaseData,
    } = useContext(CustomizeContext);

    const allDisabled =
        customizeSettings.chartTypes &&
        !customizeSettings.chartTypes.All &&
        !customizeSettings.chartTypes.SBBI &&
        !customizeSettings.chartTypes.Expansions &&
        !customizeSettings.chartTypes.Recessions &&
        !customizeSettings.chartTypes.Commodities &&
        !customizeSettings.chartTypes.InterestVsInflation &&
        !customizeSettings.chartTypes.Government &&
        !customizeSettings.chartTypes.TaxRate &&
        !customizeSettings.chartTypes.SP500

    const recessionEnabled =
        customizeSettings.chartTypes &&
        !customizeSettings.chartTypes.All &&
        !customizeSettings.chartTypes.SBBI &&
        !customizeSettings.chartTypes.Expansions &&
        customizeSettings.chartTypes.Recessions &&
        !customizeSettings.chartTypes.Commodities &&
        !customizeSettings.chartTypes.InterestVsInflation &&
        !customizeSettings.chartTypes.Government &&
        !customizeSettings.chartTypes.TaxRate &&
        !customizeSettings.chartTypes.SP500

    function getDisabledChartsBefore(chartName) {
        const chartOrder = [
            "Expansions",
            "Commodities",
            "InterestVsInflation",
            "TaxRate",
            "Government",
            "SP500",
            "SBBI",
            "Recessions"
        ];

        let count = 0;

        for (const chart of chartOrder) {
            if (chart === chartName) {
                break;
            }

            if (customizeSettings.chartTypes[chart]) {
                count++;
            }
        }

        return count;
    }

    const chartData = [];

    useEffect(
        () => {
            const fetchRebaseData = async () => {
                setActiveRebaseData(await customizeSettings.activeRebase);
            };
            fetchRebaseData();
        },
        [
            customizeSettings.activeRebase
        ]
    );

    // Clicking recessions legend item should toggle the visibility of the recessions shapes
    useEffect(() => {
        const handleLegendClick = () => {
            const plot = document.getElementById('PlotlyGraph');

            if (!plot) {
                console.warn("PlotlyGraph element not found");
                return;
            }

            plot.on('plotly_restyle', (eventData) => {
                const visibilityChanges = eventData[0].visible; // Array of new visibility states
                const affectedTraceIndices = eventData[1]; // Array of affected trace indices

                affectedTraceIndices.forEach((traceIndex, idx) => {
                    const trace = plot.data[traceIndex];

                    // Check for specific trace by name or identifier
                    if (trace && trace.name === "Recessions<br>(Vertical Bars)<br>National Bureau of<br> Economic Research") {
                        const isOn = visibilityChanges[idx] !== "legendonly";
                        setRecessionsLegendOn(isOn);
                    }
                });
            });
        };

        // Attach listener to monitor for clicks
        const plotContainer = document.getElementById('PlotlyGraph');

        if (plotContainer) {
            plotContainer.addEventListener('click', handleLegendClick);
        }

        // Cleanup function to remove the event listener on unmount
        return () => {
            if (plotContainer) {
                plotContainer.removeEventListener('click', handleLegendClick);
                plotContainer.removeAllListeners && plotContainer.removeAllListeners('plotly_restyle');
            }
        };
    }, []);

    const filteredEvents = useMemo(
        () => {
            if (!historicalEvents || historicalEvents.length === 0) return [];

            // Filter events based on checkedItems
            return historicalEvents.filter((event) => {
                if (checkedItems.allEvents) {
                    return true; // Show all events if 'All Events' is checked
                } else {
                    // Filter by specific types if checkboxes are checked
                    return (
                        (checkedItems.warConflict && event.Type === 'Wars/Conflicts') ||
                        (checkedItems.fiscalEconomic && event.Type === 'Fiscal/Economic') ||
                        (checkedItems.worldGeopolitical &&
                            event.Type === 'World/Geopolitical') ||
                        (checkedItems.congressionalGovernment &&
                            event.Type === 'Congressional/Government') ||
                        (checkedItems.scienceTechnology &&
                            event.Type === 'Science/Technology') ||
                        (checkedItems.naturalGeological &&
                            event.Type === 'Natural/Geological')
                    );
                }
            });
        },
        [
            historicalEvents,
            checkedItems
        ]
    );

    const annotations = useMemo(
        () => {
            const BAND_RANGE = [
                0,
                4.8
            ];
            const BAND_INCREMENT = 0.2;
            let yBand = 0;
            return _(filteredEvents)
                .orderBy('Date')
                .map((e) => {
                    yBand += BAND_INCREMENT;
                    yBand = yBand > BAND_RANGE[1] ? BAND_RANGE[0] : yBand;
                    let dynamicXAnchor = 'left';
                    let dynamicAX = 4;
                    if (e.Date > '2014-01-01') {
                        dynamicXAnchor = 'right'; // Adjust as needed
                        dynamicAX = -4;
                    }
                    return {
                        x: e.Date,
                        y: yBand,
                        text: e.Event,
                        showarrow: true,
                        arrowhead: 6,
                        ax: dynamicAX,
                        ay: 0,
                        xanchor: dynamicXAnchor,
                        font: { color: 'rgba(0, 0, 0, 0.6)', size: 10 },
                        arrowcolor: 'rgba(0, 0, 0, 0.6)'
                    };
                })
                .value();
        },
        [
            filteredEvents
        ]
    );

    if (
        loadingSbbi ||
        loadingEvents ||
        loadingRecessions ||
        loadingEconomicCycles ||
        loadingCommodities ||
        loadingInterestRateInflationData ||
        loadingPortfoliosData ||
        loadingSP500Data ||
        loadingGovernmentData
    ) {
        return <div>Loading Full Andex view...</div>;
    }

    if (!sbbi || sbbi.length === 0) {
        return <div>No data available for the SBBI (all-time) chart.</div>;
    }

    const sbbiData = customizeSettings.activeRebase.length > 0 ? customizeSettings.activeRebase : sbbi;

    function generateCustomLegend(metric, sbbiData) {
        const firstValue = sbbiData[0][metric];
        const lastValue = sbbiData.slice(-1)[0][metric];
        const color = getSbbiTraceColor(metric);
        let name = '';
        name = getSbbiTraceCommonName(metric);
        const apr = calculateContinuousCompoundingAPR(firstValue, lastValue, 98);

        const legendHTML = `<span style="color:${color}"><br />${name}<br />${dollarsWithCommas(
            lastValue
        )} (${apr}%)</span>`;

        return legendHTML;
    }

    // Get list of metrics (excluding 'Date')
    const sbbiMetrics = (() => {
        switch (customizeSettings.activePortfolioType) {
            case 'Balanced':
                return Object.keys(sbbiData[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Growth_Portfolio' && key !== 'Income_Portfolio' && key !== 'Global_Portfolio'
                );
            case 'Income':
                return Object.keys(sbbiData[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Growth_Portfolio' && key !== 'Balanced_Portfolio' && key !== 'Global_Portfolio'
                );
            case 'Growth':
                return Object.keys(sbbiData[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Income_Portfolio' && key !== 'Balanced_Portfolio' && key !== 'Global_Portfolio'
                );
            default:
                return Object.keys(sbbiData[0]).filter(
                    (key) =>
                        key !== 'Date' && key !== 'Income_Portfolio' && key !== 'Growth_Portfolio'
                );
        }
    })();

    const sbbiTraces = sbbiMetrics.flatMap((metric) => {
        if (metric === 'PortfolioName') {
            return [];
        }
        if (metric === 'XIUSA000RX') {
            return [];
        }
        if (metric === 'CPIAUCNS') {
            // Separate data into above and below 1
            const aboveOne = sbbiData
                .map((entry) => entry[metric])
                .map((val) => (val >= 1 ? val : null)); // Null values for below threshold
            const belowOne = sbbiData
                .map((entry) => entry[metric])
                .map((val) => (val < 1 ? val : null)); // Null values for above threshold

            const hoverText = sbbiData.map((entry) => {
                const formattedDate = new Date(entry.Date).toISOString().split('T')[0];
                return (
                    getSbbiTraceCommonName(metric) +
                    '<br />' +
                    formattedDate + // Use the formatted date here
                    'x' +
                    '<br />' +
                    '$' +
                    Math.floor(entry[metric])
                );
            });

            const fullTrace = {
                name: generateCustomLegend(metric, sbbiData),
                x: sbbiData.map((entry) => entry.Date),
                y: sbbiData.map((entry) => entry[metric]),
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                showlegend: true,
                legendgroup: metric,
                line: { color: getSbbiTraceColor(metric) },
                hoverinfo: 'text',
                hovertext: hoverText
            };

            const oneTrace = {
                name: 'SBBI One',
                x: sbbiData.map((entry) => entry.Date),
                y: sbbiData.map((entry) => 1),
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                showlegend: false,
                legendgroup: metric,
                line: { color: 'rgba(0, 0, 0, 0)' }
            };

            // Create traces for above 1, below 1, and fill up to 1
            const positiveTrace = {
                name: `${getSbbiTraceCommonName(metric)} (Positive)`,
                x: sbbiData.map((entry) => entry.Date),
                y: aboveOne,
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                line: { color: 'rgba(0, 0, 0, 0)' },
                fill: 'tonexty',
                fillcolor: 'rgba(150, 150, 150, 0.45)',
                showlegend: false,
                legendgroup: metric,
            };

            const negativeTrace = {
                name: `${getSbbiTraceCommonName(metric)} (Negative)`,
                x: sbbiData.map((entry) => entry.Date),
                y: belowOne,
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                line: { color: 'rgba(0, 0, 0, 0)' },
                fill: 'tonexty',
                fillcolor: 'rgba(150, 150, 150, 0.45)',
                showlegend: false,
                legendgroup: metric,
            };

            //Add oneTrace before each of the others, so "fill to next y" fills to one
            return [
                fullTrace,
                oneTrace,
                positiveTrace,
                oneTrace,
                negativeTrace
            ];
        } else {
            // For other metrics, create a single trace
            const hoverText = sbbiData.map((entry) => {
                const formattedDate = new Date(entry.Date).toISOString().split('T')[0];
                const commonName = getSbbiTraceCommonName(metric);

                return (
                    `${commonName}<br />` +
                    `${formattedDate}<br />` +
                    `$${Math.floor(entry[metric])}`
                );
            });

            return {
                name: generateCustomLegend(metric, sbbiData),
                x: sbbiData.map((entry) => entry.Date),
                y: sbbiData.map((entry) => entry[metric]),
                xaxis: 'x1',
                yaxis: 'y1',
                type: 'scatter',
                mode: 'lines',
                line: { color: getSbbiTraceColor(metric) },
                fill: 'none',
                showlegend: true,
                hoverinfo: 'text',
                hovertext: hoverText
            };
        }
    });

    // Calculate final y-values and sort traces by highest final y-value to lowest
    // This assists the legend, that will spit out the traces in the order added to
    // the chart data. We want them ordered so they sort of line up with the ending
    // points of the traces.
    const sortedSbbiTraces = sbbiTraces.slice().sort((traceA, traceB) => {
        const showLegendA = traceA.showlegend === true;
        const showLegendB = traceB.showlegend === true;

        if (showLegendA && showLegendB) {
            // Sort legends with showlegend === true by final Y value
            const finalYValueA = findFinalYValue(traceA);
            const finalYValueB = findFinalYValue(traceB);
            return finalYValueB - finalYValueA; // Sort in ascending order
        } else if (showLegendA && !showLegendB) {
            // traceA should come before traceB
            return -1;
        } else if (!showLegendA && showLegendB) {
            // traceB should come before traceA
            return 1;
        } else {
            // Neither has showlegend === true, maintain original order
            return 0;
        }
    });

    function findFinalYValue(trace) {
        if (Array.isArray(trace.y)) {
            for (let i = trace.y.length - 1; i >= 0; i--) {
                if (trace.y[i] !== null && !isNaN(trace.y[i])) {
                    return trace.y[i];
                }
            }
        }
        return -Infinity; // Return a very low value if no valid y-value found
    }

    const sbbiYAxisTicks = {
        tickValues: [
            1,
            10,
            100,
            1000,
            10000,
            100000,
            1000000,
            10000000,
            100000000,
            1000000000,
            50000000000,
            1000000000000
        ],
        tickText: [
            '1',
            '10',
            '100',
            '1K',
            '10K',
            '100K',
            '1M',
            '10M',
            '100M',
            '1B',
            '50B',
            '1T'
        ]
    };

    // Get current year
    const currentYear = new Date().getFullYear();

    // Generate years array from 1926 to current year
    const startYear = 1926;
    const years = Array.from(
        { length: currentYear - startYear + 1 },
        (_, index) => {
            const year = startYear + index;
            return year.toString().slice(-2); // Convert year to 2-digit format
        }
    );


    /* Position changing graphs */
    const positionDoughnutGraphVertically = () => {
        let position = 425;

        if (customizeSettings.chartTypes.SBBI) {
            position = customizeSettings.chartTypes.Recessions ? 600 : 425
        } else {
            position = customizeSettings.chartTypes.Recessions ? 90 : 0
        }

        return position;
    }

    const positionRecession = () => {
        const chart = 'Recessions'
        const numberChartsOnBefore = getDisabledChartsBefore(chart);

        let y0 = 0;
        if (recessionEnabled) return 0.5;
        switch (numberChartsOnBefore) {
            case 7:
                y0 = 0;
                break;
            case 6:
                y0 = 0.12;
                break;
            case 5:
                y0 = .24;
                break;
            case 4:
                y0 = .34;
                break;
            case 3:
                y0 = .46;
                break;
            case 2:
                y0 = 0.6;
                break;
            case 1:
                y0 = 0.8;
                break;
            case 0:
                y0 = 1;
                break;
            default:
                break;
        }
        return y0;
    }

    // Adjusting this position will adjust the legend as well
    const positionExpansionsContractions = () => {
        return customizeSettings.chartTypes.SBBI ? [0.65, 0.75] : [0.88, 0.98]
    }

    // Legend for Expansions, Contractions chart
    const positionLegend2 = () => {
        const expansionsPosition = positionExpansionsContractions();
        const yaxisDomain = expansionsPosition.map(value => value + 0.105);
        return yaxisDomain.map(value => Math.max(value, 0))[0];
    };

    const positionCommodities = () => {
        const chart = 'Commodities'
        const numberChartsOnBefore = getDisabledChartsBefore(chart);

        if (customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 1:
                    return [0.55, 0.61];
                case 0:
                    return [0.72, 0.78];
                default:
                    break;
            }
        } else if (!customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 1:
                    return [0.79, 0.85];
                case 0:
                    return [0.92, 0.98];
                default:
                    break;
            }
        }
    }

    const positionInterestVsInflation = () => {
        const chart = 'InterestVsInflation'
        const numberChartsOnBefore = getDisabledChartsBefore(chart);


        let yaxis3And4 = []; // Keeping the x value 0.1 below the y keeps the chart in proper proportion

        if (customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 2:
                    yaxis3And4 = [0.42, 0.52];
                    break;
                case 1:
                    yaxis3And4 = [0.57, 0.67];
                    break;
                case 0:
                    yaxis3And4 = [0.68, 0.78];
                    break;
                default:
                    break;
            }
        } else if (!customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 2:
                    yaxis3And4 = [0.67, 0.77];
                    break;
                case 1:
                    yaxis3And4 = [0.77, 0.87];
                    break;
                case 0:
                    yaxis3And4 = [0.9, 1];
                    break;
                default:
                    break;
            }
        }
        return yaxis3And4;
    }

    const positionTaxRateGraph = () => {
        const chart = 'TaxRate'
        const numberChartsOnBefore = getDisabledChartsBefore(chart);

        let yaxisDomain = [0.27, 0.39]; // 0.12

        if (customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 2:
                    yaxisDomain = [0.40, 0.52];
                    break;
                case 1:
                    yaxisDomain = [0.5, 0.62];
                    break;
                case 0:
                    yaxisDomain = [0.64, 0.76];
                    break;
                default:
                    break;
            }
        } else if (!customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 3:
                    yaxisDomain = [0.53, 0.65];
                    break;
                case 2:
                    yaxisDomain = [0.63, 0.75];
                    break;
                case 1:
                    yaxisDomain = [0.73, 0.85];
                    break;
                case 0:
                    yaxisDomain = [0.88, 1];
                    break;
                default:
                    break;
            }
        }
        return yaxisDomain;
    };

    // Adjusting this position will adjust the legend, house chart, and senate chart
    const positionUSPresidentGraph = () => {
        const chart = 'Government'
        const numberChartsOnBefore = getDisabledChartsBefore(chart);

        let yaxisDomain = [0.18, 0.23];  // 0.06

        if (customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 3:
                    yaxisDomain = [0.32, 0.38];
                    break;
                case 2:
                    yaxisDomain = [0.44, 0.50];
                    break;
                case 1:
                    yaxisDomain = [0.58, 0.64];
                    break;
                case 0:
                    yaxisDomain = [0.72, 0.78];
                    break;
                default:
                    break;
            }
        } else if (!customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 4:
                    yaxisDomain = [0.44, 0.50];
                    break;
                case 3:
                    yaxisDomain = [0.56, 0.62];
                    break;
                case 2:
                    yaxisDomain = [0.69, 0.75];
                    break;
                case 1:
                    yaxisDomain = [0.81, 0.87];
                    break;
                case 0:
                    yaxisDomain = [0.94, 1];
                    break;
                default:
                    break;
            }
        }

        return yaxisDomain;
    };

    const positionUSHouseGraph = () => {
        const presidentPosition = positionUSPresidentGraph();
        const yaxisDomain = presidentPosition.map(value => value - 0.035);
        return yaxisDomain.map(value => Math.max(value, 0));
    };

    const positionUSSenateGraph = () => {
        const housePosition = positionUSHouseGraph();
        const yaxisDomain = housePosition.map(value => value - 0.035);
        return yaxisDomain.map(value => Math.max(value, 0));
    };

    // Legend for Government charts
    const positionLegend3 = () => {
        const presidentPosition = positionUSPresidentGraph();
        const yaxisDomain = presidentPosition.map(value => value + 0.065);
        return yaxisDomain.map(value => Math.max(value, 0))[0];
    };

    const positionSP500 = () => {
        const chart = 'SP500'
        const numberChartsOnBefore = getDisabledChartsBefore(chart);

        let yaxisDomain = [0, 0.1];

        if (customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 4:
                    yaxisDomain = [0.13, 0.23];
                    break;
                case 3:
                    yaxisDomain = [0.27, 0.37];
                    break;
                case 2:
                    yaxisDomain = [0.41, 0.51];
                    break;
                case 1:
                    yaxisDomain = [0.51, 0.61];
                    break;
                case 0:
                    yaxisDomain = [0.66, 0.76];
                    break;
                default:
                    break;
            }
        } if (!customizeSettings.chartTypes.SBBI) {
            switch (numberChartsOnBefore) {
                case 5:
                    yaxisDomain = [0.26, 0.36];
                    break;
                case 4:
                    yaxisDomain = [0.39, 0.49];
                    break;
                case 3:
                    yaxisDomain = [0.5, 0.6];
                    break;
                case 2:
                    yaxisDomain = [0.6, 0.7];
                    break;
                case 1:
                    yaxisDomain = [0.74, 0.84];
                    break;
                case 0:
                    yaxisDomain = [0.9, 1];
                    break;
                default:
                    break;
            }
        }

        return yaxisDomain;
    };

    const recessionData = recessionsLegendOn ? recessions : [];

    const recessionShapes = recessionData.flatMap((recession) => [
        {
            type: 'rect',
            xref: 'x',
            yref: 'paper',
            x0: recession.start_date,
            x1: recession.end_date,
            y0: positionRecession(),
            y1: 1,
            fillcolor: '#e0e0e0',
            opacity: 0.5,
            line: {
                width: 0
            },
            layer: 'below',
        }
    ]);

    // Handles the plot in two cases where no data is present. (all charts off, or just recessions drawn on)
    if (recessionEnabled) {
        let sampleTrace = sbbiTraces[0];
        sampleTrace = { ...sampleTrace, line: { color: 'rgba(255, 0, 0, 0)' }, showlegend: false }; // Hidden trace to keep graph looking right when SBBI off
        chartData.push(sampleTrace);
    }

    let sampleTrace = sbbiTraces[0];
    sampleTrace = { ...sampleTrace, xaxis: 'x1', line: { color: 'rgba(255, 0, 0, 0)' }, showlegend: false }; // Hidden trace to show x-axis on bottom
    chartData.push(sampleTrace);

    (customizeSettings.chartTypes.SBBI && chartData.push(...sortedSbbiTraces));

    //REGION: Economic Cylces
    const globalStartDate = economicCycles.reduce((min, current) => {
        const currentDate = new Date(current.StartDate);
        return currentDate < min ? currentDate : min;
    }, new Date('9999-12-31'));

    const minYear = globalStartDate.getFullYear();

    // Since return of a recovery is suspect, pin to the max
    // of non-recovery items.
    const maxReturn = Math.max(
        ...economicCycles
            .filter((item) => item.Phase !== 'Recovery')
            .map((item) => Math.abs(item.Return))
    );

    const expansions = economicCycles.filter(
        (item) => item.Phase === 'Expansion'
    );
    const downturns = economicCycles.filter((item) => item.Phase === 'Downturn');
    const recoveries = economicCycles.filter((item) => item.Phase === 'Recovery');

    const calculateXPositionInYears = (startDate, width) => {
        const localStartDate = new Date(startDate);
        const yearsFromGlobalStartDate =
            (localStartDate - globalStartDate) / (1000 * 60 * 60 * 24 * 365);
        const xPositionInYears = yearsFromGlobalStartDate + 0.5 * width;
        return xPositionInYears + minYear;
    };

    const calculateWidth = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const widthInYears = (end - start) / (1000 * 60 * 60 * 24 * 365);
        return widthInYears;
    };

    const calculateDurationInMonths = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const diffInMonths =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());

        return diffInMonths;
    };

    function floatToDate(floatNumber) {
        // Extract the year (integer part) and days fraction (decimal part)
        let year = Math.floor(floatNumber);
        let daysFraction = floatNumber - year;

        // Determine if it's a leap year
        let isLeapYear = new Date(year, 1, 29).getMonth() === 1;

        // Calculate total days in the year
        let totalDaysInYear = isLeapYear ? 366 : 365;

        // Calculate the day of the year
        let dayOfYear = Math.floor(daysFraction * totalDaysInYear) + 1; // +1 because dayOfYear is 1-based

        // Convert day of year to date format (day, month, year)
        let date = dayOfYearToDate(dayOfYear, year);

        return date;
    }

    function dayOfYearToDate(dayOfYear, year) {
        // Convert day of year to date format (day, month, year)
        let date = new Date(year, 0); // January 0th is actually December 31st of the previous year
        date.setDate(dayOfYear);
        return date;
    }

    const generateTraces = (phaseData, negative, color, legendStatus) => {
        const filteredPhaseData = phaseData;

        return filteredPhaseData.map((item) => {
            const phase = item.Phase;
            const showLegend = !legendStatus[phase];  // Show legend only once per phase
            legendStatus[phase] = true;

            const itemYValue = phase === 'Recovery'
                ? [negative === true ? -100 : 100]  // Set y to -100 or 100 based on negative
                : [
                    negative === true
                        ? Math.max(-100, Math.min(100, (-1 * 100 * item.Return) / maxReturn))
                        : Math.max(-100, Math.min(100, (100 * item.Return) / maxReturn))
                ];

            const itemYPercentage = parseFloat(itemYValue[0].toFixed(2));

            return {
                x: [
                    floatToDate(
                        calculateXPositionInYears(
                            item.StartDate,
                            calculateWidth(item.StartDate, item.EndDate)
                        )
                    )
                ],
                y: itemYValue,
                width: [
                    calculateWidth(item.StartDate, item.EndDate) * 365 * 24 * 60 * 60 * 1000
                ],
                marker: { color: color },
                name: phase,
                hoverinfo: 'text',
                showlegend: showLegend,
                hovertext: `<b>${phase}</b></br>Start Date: ${new Date(item.StartDate)
                    .toISOString()
                    .split('T')[0]}<br />End Date: ${new Date(item.EndDate)
                        .toISOString()
                        .split('T')[0]}<br />Duration (Months): ${calculateDurationInMonths(
                            item.StartDate,
                            item.EndDate
                        )}</br>Percentage: ${itemYPercentage}%`,
                type: 'bar',
                yaxis: 'y2',
                orientation: 'v',
                legend: 'legend2',
                legendgroup: phase, // Group all traces of the same phase so they all toggle from legend at once.
            };
        });
    };

    // Legend status to control showing legend once per phase
    const legendStatus = {};
    const economicCycleTraces = [
        ...generateTraces(expansions, false, 'rgba(123, 207, 235, 0.7)', legendStatus),
        ...generateTraces(downturns, false, 'rgba(0, 168, 225, 0.7)', legendStatus),
        ...generateTraces(recoveries, false, 'rgba(204, 238, 249, 0.7)', legendStatus),
        ...generateTraces(recoveries, true, 'rgba(204, 238, 249, 0.7)', legendStatus)
    ];

    (customizeSettings.chartTypes.Expansions && chartData.push(...economicCycleTraces));
    //END REGION: Economic Cylces

    //REGION: Commodities
    //const filteredCommoditiesData = commodities.filter(item => new Date(item.Date) >= thirtyYearsAgo);
    const filteredCommoditiesData = commodities;

    const traceOil = {
        name: 'Oil',
        x: filteredCommoditiesData.map((e) => e.Date),
        y: filteredCommoditiesData.map((e) => e.Oil),
        type: 'scatter',
        marker: {
            color: 'background: #414042'
        },
        yaxis: 'y3',
        xaxis: 'x1', // Bottom-most chart bound to x-axis #2
        showlegend: false
    };
    (customizeSettings.chartTypes.Commodities && chartData.push(traceOil));

    const traceGold = {
        name: 'Gold',
        x: filteredCommoditiesData.map((e) => e.Date),
        y: filteredCommoditiesData.map((e) => e.Gold),
        type: 'scatter',
        marker: {
            color: '#F3C317'
        },
        yaxis: 'y4',
        showlegend: false,
    };
    (customizeSettings.chartTypes.Commodities && chartData.push(traceGold));
    //END REGION: Commodities

    // REGION: Interest Rate vs. Inflation
    const tracePrimeInterest = {
        name: 'Prime Interest',
        x: interestRateInflationData.map((e) => e.Date),
        y: interestRateInflationData.map((e) => e.prime_interest),
        hoverinfo: 'text',
        hovertext: interestRateInflationData.map((e) => {
            return `<b>Prime Interest Rate</b><br />${new Date(e.Date)
                .toISOString()
                .split('T')[0]}<br />${e.prime_interest}`;
        }),
        type: 'scatter',
        mode: 'lines',
        fill: 'tozeroy', // Fill area below the line
        line: {
            color: '#F3C317'
        },
        fillcolor: 'rgba(243, 195, 23, 1)', // Solid color for fill area
        yaxis: 'y5',
        showlegend: false
    };
    (customizeSettings.chartTypes.InterestVsInflation && chartData.push(tracePrimeInterest));

    const traceInflation = {
        name: 'Inflation',
        x: interestRateInflationData.map((e) => e.Date),
        y: interestRateInflationData.map((e) => e.rolling_inflation),
        hoverinfo: 'text',
        hovertext: interestRateInflationData.map((e) => {
            return `<b>Inflation Rate</b><br />${new Date(e.Date)
                .toISOString()
                .split('T')[0]}<br />${e.rolling_inflation}`;
        }),
        type: 'scatter',
        mode: 'lines',
        fill: 'tozeroy', // Fill area below the line
        line: {
            color: '#868686'
        },
        fillcolor: 'rgba(134, 134, 134, 1)', // Solid color for fill area
        yaxis: 'y6',
        xaxis: 'x1', // Bottom-most chart bound to x-axis #2
        showlegend: false
    };
    (customizeSettings.chartTypes.InterestVsInflation && chartData.push(traceInflation));

    const trace30YearGovernmentBond = {
        name: "30Y Gov't Bond",
        x: interestRateInflationData.map((e) => e.Date),
        y: interestRateInflationData.map((e) => e.thirty_yr_gov_bond),
        type: 'scatter',
        marker: {
            color: '#EE7623'
        },
        line: {
            color: '#EE7623',
            width: 2 // Adjust line width as needed
        },
        yaxis: 'y6',
        xaxis: 'x1', // Bottom-most chart bound to x-axis #2
        showlegend: false
    };
    (customizeSettings.chartTypes.InterestVsInflation && chartData.push(trace30YearGovernmentBond));
    // END REGION: Interest Rate vs. Inflation


    // Maybe we could use this API to keep these sections updated: https://gpo.congress.gov/#/
    // Otherwise quick access to list: https://history.house.gov/Institution/Presidents-Coinciding/Party-Government/
    const generateDateRange = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dateArray = [];
        let currentDate = new Date(start);

        // Add dates to the array incrementally (e.g., daily for simplicity)
        while (currentDate <= end) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1); // Increment by one day
        }

        return dateArray;
    };

    // REGION: Top Federal Tax Rate
    const topTaxRates = [{ "value": 25, "date": "1926-01-01T00:00:00" }, { "value": 25, "date": "1927-01-01T00:00:00" },
    { "value": 25, "date": "1928-01-01T00:00:00" }, { "value": 25, "date": "1929-01-01T00:00:00" }, { "value": 25, "date": "1930-01-01T00:00:00" },
    { "value": 25, "date": "1931-01-01T00:00:00" }, { "value": 63, "date": "1932-01-01T00:00:00" }, { "value": 63, "date": "1933-01-01T00:00:00" },
    { "value": 63, "date": "1934-01-01T00:00:00" }, { "value": 63, "date": "1935-01-01T00:00:00" }, { "value": 79, "date": "1936-01-01T00:00:00" },
    { "value": 79, "date": "1937-01-01T00:00:00" }, { "value": 79, "date": "1938-01-01T00:00:00" }, { "value": 79, "date": "1939-01-01T00:00:00" },
    { "value": 79, "date": "1940-01-01T00:00:00" }, { "value": 81, "date": "1941-01-01T00:00:00" }, { "value": 88, "date": "1942-01-01T00:00:00" },
    { "value": 88, "date": "1943-01-01T00:00:00" }, { "value": 94, "date": "1944-01-01T00:00:00" }, { "value": 94, "date": "1945-01-01T00:00:00" },
    { "value": 91, "date": "1946-01-01T00:00:00" }, { "value": 91, "date": "1947-01-01T00:00:00" }, { "value": 91, "date": "1948-01-01T00:00:00" },
    { "value": 91, "date": "1949-01-01T00:00:00" }, { "value": 91, "date": "1950-01-01T00:00:00" }, { "value": 91, "date": "1951-01-01T00:00:00" },
    { "value": 92, "date": "1953-01-01T00:00:00" }, { "value": 92, "date": "1953-01-01T00:00:00" }, { "value": 91, "date": "1954-01-01T00:00:00" },
    { "value": 91, "date": "1955-01-01T00:00:00" }, { "value": 91, "date": "1956-01-01T00:00:00" }, { "value": 91, "date": "1957-01-01T00:00:00" },
    { "value": 91, "date": "1958-01-01T00:00:00" }, { "value": 91, "date": "1959-01-01T00:00:00" }, { "value": 91, "date": "1960-01-01T00:00:00" },
    { "value": 91, "date": "1961-01-01T00:00:00" }, { "value": 91, "date": "1962-01-01T00:00:00" }, { "value": 91, "date": "1963-01-01T00:00:00" },
    { "value": 77, "date": "1964-01-01T00:00:00" }, { "value": 70, "date": "1965-01-01T00:00:00" }, { "value": 70, "date": "1966-01-01T00:00:00" },
    { "value": 70, "date": "1967-01-01T00:00:00" }, { "value": 70, "date": "1968-01-01T00:00:00" }, { "value": 75, "date": "1969-01-01T00:00:00" },
    { "value": 78, "date": "1970-01-01T00:00:00" }, { "value": 70, "date": "1971-01-01T00:00:00" }, { "value": 70, "date": "1972-01-01T00:00:00" },
    { "value": 70, "date": "1973-01-01T00:00:00" }, { "value": 70, "date": "1974-01-01T00:00:00" }, { "value": 70, "date": "1975-01-01T00:00:00" },
    { "value": 70, "date": "1976-01-01T00:00:00" }, { "value": 70, "date": "1977-01-01T00:00:00" }, { "value": 70, "date": "1978-01-01T00:00:00" },
    { "value": 70, "date": "1979-01-01T00:00:00" }, { "value": 70, "date": "1980-01-01T00:00:00" }, { "value": 70, "date": "1981-01-01T00:00:00" },
    { "value": 50, "date": "1982-01-01T00:00:00" }, { "value": 50, "date": "1983-01-01T00:00:00" }, { "value": 50, "date": "1984-01-01T00:00:00" },
    { "value": 50, "date": "1985-01-01T00:00:00" }, { "value": 50, "date": "1986-01-01T00:00:00" }, { "value": 38.5, "date": "1987-01-01T00:00:00" },
    { "value": 28, "date": "1988-01-01T00:00:00" }, { "value": 28, "date": "1989-01-01T00:00:00" }, { "value": 28, "date": "1990-01-01T00:00:00" },
    { "value": 31, "date": "1991-01-01T00:00:00" }, { "value": 31, "date": "1992-01-01T00:00:00" }, { "value": 39.6, "date": "1993-01-01T00:00:00" },
    { "value": 39.6, "date": "1994-01-01T00:00:00" }, { "value": 39.6, "date": "1995-01-01T00:00:00" }, { "value": 39.6, "date": "1996-01-01T00:00:00" },
    { "value": 39.6, "date": "1997-01-01T00:00:00" }, { "value": 39.6, "date": "1998-01-01T00:00:00" }, { "value": 39.6, "date": "1999-01-01T00:00:00" },
    { "value": 39.6, "date": "2000-01-01T00:00:00" }, { "value": 39.1, "date": "2001-01-01T00:00:00" }, { "value": 38.6, "date": "2002-01-01T00:00:00" },
    { "value": 35, "date": "2003-01-01T00:00:00" }, { "value": 35, "date": "2004-01-01T00:00:00" }, { "value": 35, "date": "2005-01-01T00:00:00" },
    { "value": 35, "date": "2006-01-01T00:00:00" }, { "value": 35, "date": "2007-01-01T00:00:00" }, { "value": 35, "date": "2008-01-01T00:00:00" },
    { "value": 35, "date": "2009-01-01T00:00:00" }, { "value": 35, "date": "2010-01-01T00:00:00" }, { "value": 35, "date": "2011-01-01T00:00:00" },
    { "value": 35, "date": "2012-01-01T00:00:00" }, { "value": 39.6, "date": "2013-01-01T00:00:00" }, { "value": 39.6, "date": "2014-01-01T00:00:00" },
    { "value": 39.6, "date": "2015-01-01T00:00:00" }, { "value": 39.6, "date": "2016-01-01T00:00:00" }, { "value": 39.6, "date": "2017-01-01T00:00:00" },
    { "value": 37, "date": "2018-01-01T00:00:00" }, { "value": 37, "date": "2019-01-01T00:00:00" }, { "value": 37, "date": "2020-01-01T00:00:00" },
    { "value": 37, "date": "2021-01-01T00:00:00" }, { "value": 37, "date": "2022-01-01T00:00:00" }, { "value": 37, "date": "2023-01-01T00:00:00" },
    { "value": 37, "date": "2024-01-01T00:00:00" }]

    const traceForTaxRates = {
        x: topTaxRates.map(item => item.date),
        y: topTaxRates.map(item => item.value),
        type: 'scatter',
        mode: 'lines',
        marker: { color: 'blue' },
        name: 'Top Federal Tax Rate',
        hovertemplate: '%{y}% <br>Year: %{x}<extra></extra>',
        xaxis: 'x',
        yaxis: 'y7',
        showlegend: false,
    };

    (customizeSettings.chartTypes.TaxRate && chartData.push(...[traceForTaxRates]));

    /// End Region: Top Federal Tax Rate

    // REGION: US President
    // Start year month set to 05 and end year month set to 01 to allow small gap between...............
    const presidents = governmentData.USPresidentsData;

    const traceForPresidents = presidents.map((president) => {
        const dateRange = generateDateRange(president.Start_Date, president.End_Date).map(date => date.toISOString().split('T')[0]);
        return {
            x: dateRange,  // This now includes the full range of dates
            y: new Array(dateRange.length).fill(0.5),  // The same y value across the entire range
            mode: 'lines',
            line: {
                width: 18,  // Height of bar in this case
                color: president.Party === 'Republican' ? 'red' : 'blue',
            },
            name: president.Last_Name,
            yaxis: 'y8',
            legendgroup: president.Party,
            legend: 'legend3',
            showlegend: false, // Can just show Senate legend and legendGroup takes care of toggle still
            hovertemplate: `${new Date(president.Start_Date).getFullYear()}-${new Date(president.End_Date).getFullYear()}<br />${president.Last_Name}<extra></extra>`,
        };
    });
    // END REGION: US President

    // REGION: US House Control
    const houseControl = governmentData.USHouseData;

    const legendStatusHouse = { Republican: false, Democratic: false };

    const traceForHouse = houseControl.map((house) => {
        const dateRange = generateDateRange(house.Start_Date, house.End_Date).map(date => date.toISOString().split('T')[0]);
        legendStatusHouse[house.Party] = true;

        return {
            x: dateRange,
            y: new Array(dateRange.length).fill(0.5),
            mode: 'lines',
            line: {
                width: 18,  // Height of bar in this case
                color: house.Party === 'Republican' ? 'red' : 'blue'
            },
            name: house.Party,
            yaxis: 'y9',
            showlegend: false, // Can just show Senate legend and legendGroup takes care of toggle still
            legendgroup: house.Party,
            legend: 'legend3',
            hovertemplate: `${new Date(house.Start_Date).getFullYear()}-${new Date(house.End_Date).getFullYear()}<br />${house.Party} House<extra></extra>`,
        };
    });
    // END REGEION: US House Control

    // REGION: US Senate Control
    const senateControl = governmentData.USSenateData
    const legendStatusSenate = { Republican: false, Democratic: false };

    const traceForSenate = senateControl.map((senate) => {
        const dateRange = generateDateRange(senate.Start_Date, senate.End_Date).map(date => date.toISOString().split('T')[0]);
        const showLegend = !legendStatusSenate[senate.Party];  // Show legend only once per party.
        legendStatusSenate[senate.Party] = true;

        return {
            x: dateRange,
            y: new Array(dateRange.length).fill(0.2),
            mode: 'lines',
            line: {
                width: 18,  // Height of bar in this case
                color: senate.Party === 'Republican' ? 'red' : 'blue'
            },
            name: senate.Party,
            yaxis: 'y10',
            showlegend: showLegend,
            legendgroup: senate.Party,
            legend: 'legend3',
            hovertemplate: `${new Date(senate.Start_Date).getFullYear()}-${new Date(senate.End_Date).getFullYear()}<br />${senate.Party} Senate<extra></extra>`,
        };
    });

    (customizeSettings.chartTypes.Government && chartData.push(...traceForPresidents));
    (customizeSettings.chartTypes.Government && chartData.push(...traceForHouse));
    (customizeSettings.chartTypes.Government && chartData.push(...traceForSenate));
    // END REGION: US Senate Control

    // REGION: S&P 500 PE Ratio
    const filteredSP500PEData = sP500PEData;

    const values = filteredSP500PEData.map(item => item.Value);
    const sortedValues = values.sort((a, b) => a - b);
    let median;
    if (sortedValues.length % 2 === 0) {
        const mid1 = sortedValues[sortedValues.length / 2 - 1];
        const mid2 = sortedValues[sortedValues.length / 2];
        median = (mid1 + mid2) / 2;
    } else {
        median = sortedValues[Math.floor(sortedValues.length / 2)];
    }

    const medianLine = {
        x: [filteredSP500PEData[0].Date, filteredSP500PEData[filteredSP500PEData.length - 1].Date],
        y: [median, median],
        mode: 'lines',
        yaxis: 'y11',
        line: { color: 'rgba(34, 187, 173, 1)', dash: 'solid' },
        showlegend: false,
    };

    const filteredSP500PETrace = {
        x: filteredSP500PEData.map(item => item.Date),
        y: filteredSP500PEData.map(item => item.Value),
        mode: 'lines',
        type: 'scatter',
        fill: 'tonexty',
        fillcolor: 'rgba(34, 187, 173, 1)',
        line: { color: 'rgba(34, 187, 173, 1)' },
        yaxis: 'y11',
        name: 'S&P 500 (PE Ratio)',
        showlegend: false,
    };

    (customizeSettings.chartTypes.SP500 && chartData.push(...[medianLine]));
    (customizeSettings.chartTypes.SP500 && chartData.push(...[filteredSP500PETrace]));
    // END REGION: S&P 500 PR Ratio

    /// REGION: Portfolio (balanced)
    let balancedPortfolioData = customizeSettings.portfolioData && customizeSettings.portfolioData[customizeSettings.activePortfolioType.toLowerCase()];
    balancedPortfolioData = Object.fromEntries(
        Object.entries(balancedPortfolioData).filter(([key]) => !key.includes("Percentage"))
    );

    const formatLabel = (name, percentage) => {
        const maxLength = 20; // Define a max length for the name for padding
        const namePadded = name.padEnd(maxLength, ' '); // Pad the name with spaces to the right
        return `${namePadded}${percentage}`; // Combine the padded name and percentage
    };

    // Define new colors for the pie chart
    const pieChartColors = [
        '#1268B3',
        '#8CA2D3',
        '#EE7623',
        '#658D3C'
    ];

    // Determine the legend label for LT Government Bonds
    const determineLegendLabel = (commonName) => {
        if (commonName === 'smallStocks') {
            return 'Small Stocks';
        } if (commonName === 'largeStocks') {
            return 'Large Stocks';
        }
        if (commonName === 'bonds') {
            return 'Bonds';
        }
        if (commonName === 'other') {
            return 'Other';
        }
        if (commonName === 'cash') {
            return 'Cash';
        }
        return commonName;
    };

    const balancedPortfolioChartData = balancedPortfolioData ? [
        {
            values: Object.values(balancedPortfolioData).map((value) => value / 100),
            labels: Object.entries(balancedPortfolioData).map(([key, value]) =>
                formatLabel(determineLegendLabel(key), value)
            ),
            type: 'pie',
            hole: 0.4,
            textinfo: 'none', // Hide percentage labels inside the pie chart
            insidetextorientation: 'radial', // Ensure text is oriented properly
            marker: {
                colors: pieChartColors.slice(0, balancedPortfolioData.length) // Use the defined colors for the pie chart
            },
            domain: {
                x: [
                    0,
                    1
                ], // Increase the size of the pie chart horizontally
                y: [
                    0.25,
                    1
                ] // Increase the size of the pie chart vertically, starting higher up
            }
        }
    ] : [];

    const balancedPortfolioChartLayout = {
        title: {
            text: `${customizeSettings.activePortfolioType}<br>Portfolio %`,
            y: 0.88, // Adjust this value to move the title closer to the chart
            yanchor: 'bottom', // Anchor the title at the bottom to move it closer to the chart
            font: {
                size: 14
            }
        },
        showlegend: true,
        legend: {
            orientation: 'h', // Legend orientation (horizontal)
            xanchor: 'center', // Center the legend horizontally
            yanchor: 'bottom', // Anchor the legend at the top
            x: 0.5,
            y: 0.16, // Adjust this value to move the legend closer to the chart
            font: {
                size: 10
            }
        },
        margin: {
            t: 0, // Remove top margin
            b: 0, // Remove bottom margin
            r: 10, // Right margin
            l: 10 // Left margin
        },
        height: 400

    };
    // END REGION: Portfolio (balanced)

    const placeX2OnBottomChart = () => {
        switch (true) {
            case customizeSettings.chartTypes.SP500:
                return 'y11';
            case customizeSettings.chartTypes.Government:
                return 'y10';
            case customizeSettings.chartTypes.TaxRate:
                return 'y7';
            case customizeSettings.chartTypes.InterestVsInflation:
                return 'y5';
            case customizeSettings.chartTypes.Commodities:
                return 'y4';
            case customizeSettings.chartTypes.Expansions:
                return 'y2';
            case customizeSettings.chartTypes.SBBI:
            default:
                return 'y1';
        }
    }

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <div style={{ width: '100%', minWidth: '300px' }}>
                {!allDisabled && (
                    <Plot
                        divId='PlotlyGraph'
                        data={[
                            ...(zoomedChartData && zoomedChartData.length > 0 ? zoomedChartData : chartData),
                            ...(customizeSettings.chartTypes.Recessions ? [
                                {
                                    // Recessions legend item
                                    x: zoomedDates.length > 0 ? zoomedDates : ['1926-01-01', '2024-06-01'],
                                    y: [null, null],
                                    mode: 'lines',
                                    line: {
                                        color: '#e0e0e0',
                                        width: 10,
                                    },
                                    showlegend: customizeSettings.chartTypes.Recessions,
                                    name: `Recessions<br>(Vertical Bars)<br>National Bureau of<br> Economic Research`,
                                    hoverinfo: 'skip',
                                    legend: {
                                        orientation: 'v',
                                        y: 1.05,
                                    },
                                    zeroline: false,
                                }
                            ] : []) // Add nothing if `recession` is null
                        ]}
                        layout={{
                            height: 1600,
                            yaxis: customizeSettings.chartTypes.SBBI
                                ? {
                                    domain: [0.8, 1],
                                    title: {
                                        text: 'Growth of $1 (with no acquisition costs or taxes and all income reinvested)',
                                        font: { size: 11 },
                                    },
                                    type: 'log',
                                    tickvals: sbbiYAxisTicks.tickValues,
                                    ticktext: sbbiYAxisTicks.tickText,
                                    fixedrange: true,
                                }
                                : {
                                    domain: [0, 0], // Hides yaxis when SBBI is false by collapsing its domain
                                    title: '',
                                    tickvals: [],
                                    ticktext: [],
                                },
                            yaxis2: {
                                domain: positionExpansionsContractions(),
                                fixedrange: true,
                            },
                            yaxis3: {
                                domain: positionCommodities(),
                                title: 'Oil',
                                side: 'left',
                                fixedrange: true,
                            },
                            yaxis4: {
                                domain: positionCommodities(),
                                title: 'Gold',
                                side: 'right',
                                fixedrange: true,
                                overlaying: 'y3',
                            },
                            yaxis5: {
                                title: 'Prime Interest',
                                domain: positionInterestVsInflation(), // Ensure the entire range is visible
                                side: 'left',
                                range: [-15, 26], // This overlays them nicely. TODO: params or declarative
                                fixedrange: true,
                            },
                            yaxis6: {
                                title: 'Inflation',
                                domain: positionInterestVsInflation(),
                                side: 'right',
                                range: [-15, 26],
                                fixedrange: true,
                                overlaying: 'y5',
                            },
                            yaxis7: {
                                title: 'Top Federal Tax Rate',
                                domain: positionTaxRateGraph(),
                                side: 'left',
                                zeroline: false,
                                range: [10, 100],
                                dtick: 10,
                                ticksuffix: "%",
                                fixedrange: true,
                            },
                            yaxis8: {
                                title: {
                                    text: 'President',
                                    standoff: 10,
                                },
                                domain: positionUSPresidentGraph(),
                                side: 'right',
                                showticklabels: false,
                                zeroline: false,
                                fixedrange: true,
                            },
                            yaxis9: {
                                title: {
                                    text: 'House',
                                    standoff: 25,
                                },
                                domain: positionUSHouseGraph(),
                                side: 'right',
                                showticklabels: false,
                                zeroline: false,
                                fixedrange: true,
                            },
                            yaxis10: {
                                title: {
                                    text: 'Senate',
                                    standoff: 40,
                                },
                                domain: positionUSSenateGraph(),
                                side: 'right',
                                showticklabels: false,
                                zeroline: false,
                                fixedrange: true,
                            },
                            yaxis11: {
                                title: 'S&P 500 (PE Ratio)',
                                dtick: 10,
                                range: [0, 40],
                                domain: positionSP500(),
                                side: 'left',
                                fixedrange: true,
                            },
                            xaxis: {
                                range: zoomedDates.length > 0 ? zoomedDates : ['1926-01-01', '2024-06-01'],
                                domain: [0, 1],
                                ticks: 'outside',
                                side: 'top',
                                zeroline: false,
                                anchor: 'y1',
                            },
                            xaxis2: {
                                range: zoomedDates.length > 0 ? zoomedDates : ['1926-01-01', '2024-06-01'],
                                domain: [0, 1],
                                ticks: 'outside',
                                side: 'bottom',
                                zeroline: false,
                                anchor: placeX2OnBottomChart(),
                                matches: 'x',
                            },
                            margin: { r: 220, t: 30, b: 30, l: 70 },
                            showlegend: true,
                            legend: {
                                orientation: 'v',
                                y: 1.05,
                            },
                            legend2: {
                                orientation: 'h',
                                xanchor: 'left',
                                yanchor: 'top',
                                y: positionLegend2(),
                                x: 0,
                            },
                            legend3: {
                                orientation: 'h',
                                xanchor: 'left',
                                yanchor: 'top',
                                y: positionLegend3(),
                                x: 0,
                            },
                            annotations: annotations,
                            shapes: (customizeSettings.chartTypes.Recessions ? recessionShapes : ''),
                            paper_bgcolor: 'white',
                            plot_bgcolor: 'transparent',
                            dragmode: selectDragmode(selectedTool),
                        }}
                        useResizeHandler={true}
                        style={{ width: '100%', height: '100%' }}
                        config={{ displayModeBar: false }}
                    />
                )}
            </div>
            <div
                style={{
                    height: '150px',
                    width: '170px',
                    position: 'absolute',
                    top: positionDoughnutGraphVertically(),
                    right: 2,
                }}
            >
                {customizeSettings.chartTypes.SBBI && (
                    <Plot
                        data={balancedPortfolioChartData}
                        layout={balancedPortfolioChartLayout}
                        style={{ width: '95%', height: '100%' }}
                        config={{ displayModeBar: false, useResizeHandler: true }}
                    />
                )}
            </div>
        </div>
    );
}

export default React.memo(FullAndexChart); // Memo to keep charts from redrawing when exporting image
