import Plot from 'react-plotly.js';

const colors = [
    '#33A1FF', // - Vivid Sky Blue
    '#28A745', // - Bold Green
    '#FFCC00', // - Vibrant Yellow
    '#E91E63', // - Hot Pink
    '#D62728', // - Rich Crimson Red
    '#8E44AD', // - Deep Purple
    '#1F77B4', // - Strong Steel Blue
    '#FF7F0E', // - Bold Orange
    '#2CA02C', // - Bright Lime Green
    '#FF5733', // - Bright Orange-Red
];

const ReportLineChart = ({ data, chartTitle, xAxisTitle, yAxisTitle, customLayout }) => {
    if (data?.length && data.some((dataSet) => dataSet.x.length > 1000)) {
        return (
            <div className="text-red-800 font-bold my-10">
                <div>
                    Dataset too large to render this chart. Use the page or table filters
                    to reduce the size.
                </div>
            </div>
        );
    }

    const dataDecorated = data.map((x, i) => ({
        type: 'scatter',
        line: { color: colors[i] },
        ...x,
    }));

    return (
        <Plot
            data={dataDecorated}
            layout={{
                width: 750,
                height: 500,
                margin: {
                    l: 50, // Left
                    r: 50, // Right
                    t: 50, // Top
                    b: 50, // Bottom
                },
                title: {
                    text: chartTitle,
                },
                xaxis: {
                    title: xAxisTitle, // Label for x-axis
                },
                yaxis: {
                    title: yAxisTitle, // Label for y-axis
                },
                ...customLayout,
            }}
            config={{
                displayModeBar: false,
            }}
        />
    );
};

export default ReportLineChart;
