import React, { useState, useEffect, useContext } from 'react';
import Plot from 'react-plotly.js';
import { CustomizeContext } from '../../context/CustomizeContext';
import { MenuContext } from '../../context/MenuContext';
import { EI_PAGES } from '../../constants';

const PortfolioChart = ({ assetMix }) => {
  const [balancedPortfolioData, setBalancedPortfolioData] = useState([]);

  const {
    customizeSettings,
    setActivePortfolioType,
    setPortfolioData
  } = useContext(CustomizeContext);

  const {
    selectedMenuItem,
  } = useContext(MenuContext);

  useEffect(() => {
    if(selectedMenuItem.id === EI_PAGES.past30Years){
      const globalPortfolio = 'global';
      setActivePortfolioType(globalPortfolio);
      setPortfolioData(customizeSettings.portfolioData[globalPortfolio]);
    } 
    setBalancedPortfolioData(customizeSettings.portfolioData && customizeSettings.portfolioData[customizeSettings.activePortfolioType.toLowerCase()]);
  },[])

  if (!balancedPortfolioData || balancedPortfolioData.length === 0) {
    return <div>Loading Portfolio chart...</div>;
  }

  const formatLabel = (name, percentage) => {
    const maxLength = 20; // Define a max length for the name for padding
    const namePadded = name.padEnd(maxLength, ' '); // Pad the name with spaces to the right
    return `${namePadded}${percentage}`; // Combine the padded name and percentage
  };

  // Define new colors for the pie chart
  const pieChartColors = [
    '#1268B3',
    '#8CA2D3',
    '#EE7623',  
    '#658D3C'
  ];

  // Determine the legend label for LT Government Bonds
  const determineLegendLabel = (commonName) => {
    if (commonName === 'smallStocks') {
      return 'Small Stocks';
    } if (commonName === 'largeStocks') {
      return 'Large Stocks';
    }
    if (commonName === 'bonds') {
      return 'Bonds';
    }
    if (commonName === 'other') {
      return 'Other';
    }
    if (commonName === 'cash') {
      return 'Cash';
    }
    return commonName;
  };

  const filteredBalancedPortfolioData = Object.fromEntries(
    Object.entries(balancedPortfolioData).filter(([key]) => !key.includes("Percentage"))
  );

  const balancedPortfolioChartData = [
    {
      values: Object.values(filteredBalancedPortfolioData).map((value) => value / 100),
      labels: Object.entries(filteredBalancedPortfolioData).map(([key, value]) =>
        formatLabel(determineLegendLabel(key), value)
      ),
      type: 'pie',
      hole: 0.4,
      textinfo: 'none', // Hide percentage labels inside the pie chart
      insidetextorientation: 'radial', // Ensure text is oriented properly
      marker: {
        colors: pieChartColors.slice(0, filteredBalancedPortfolioData.length) // Use the defined colors for the pie chart
      },
      domain: {
        x: [
          0,
          1
        ], // Increase the size of the pie chart horizontally
        y: [
          0.25,
          1
        ] // Increase the size of the pie chart vertically, starting higher up
      }
    }
  ];

  const balancedPortfolioChartLayout = {
    title: {
      text: `${customizeSettings.activePortfolioType}<br>Portfolio %`,
      y: .94, // Adjust this value to move the title closer to the chart
      yanchor: 'top', // Anchor the title at the bottom to move it closer to the chart
      font: {
        size: 14
      }
    },
    showlegend: true,
    legend: {
      orientation: 'h', // Legend orientation (horizontal)
      xanchor: 'center', // Center the legend horizontally
      yanchor: 'bottom', // Anchor the legend at the top
      x: 0.5,
      y: -.2, // Adjust this value to move the legend closer to the chart
      font: {
        size: 10
      }
    },
    margin: {
      t: 50, // Remove top margin
      b: 50, // Remove bottom margin
      r: 10, // Right margin
      l: 10 // Left margin
    },
    height: 400

  };

  return (
    <div style={{ width: '100%', minWidth: '300px' }}>
      <Plot
        data={balancedPortfolioChartData}
        layout={balancedPortfolioChartLayout}
        style={{ width: '95%', height: '100%' }}
        config={{ displayModeBar: false, useResizeHandler: true }}
      />
    </div>
  );
};

export default React.memo(PortfolioChart); // Memo to keep charts from redrawing when exporting image
