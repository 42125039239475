import React, { useContext } from 'react';
import '../../css/ChartTypeButtonsContainer/ChartTypeButtonsContainer.css';
import ChartTypeButton from '../Buttons/ChartTypeButton';
import { MenuContext } from '../../context/MenuContext';

const ChartTypeButtonsContainer = () => {
	const { selectedButton, setSelectedButton } = useContext(MenuContext);
	return (
		<div>
			<ChartTypeButton
				label="Full Andex"
				selected={selectedButton === 'Full Andex'}
				onClick={() => setSelectedButton('Full Andex')}
			/>
			<ChartTypeButton
				label="Expansion, contraction & recovery periods"
				selected={
					selectedButton === 'Expansion, contraction & recovery periods'
				}
				onClick={() =>
					setSelectedButton('Expansion, contraction & recovery periods')}
			/>
			<ChartTypeButton
				label="Recessions"
				selected={selectedButton === 'Recessions'}
				onClick={() => setSelectedButton('Recessions')}
			/>
			<ChartTypeButton
				label="Commodities"
				selected={selectedButton === 'Commodities'}
				onClick={() => setSelectedButton('Commodities')}
			/>
			<ChartTypeButton
				label="Prime interest rate vs inflation %"
				selected={selectedButton === 'Prime interest rate vs inflation %'}
				onClick={() => setSelectedButton('Prime interest rate vs inflation %')}
			/>
			<ChartTypeButton
				label="Events"
				selected={selectedButton === 'Events'}
				onClick={() => setSelectedButton('Events')}
			/>
		</div>
	);
};

export default ChartTypeButtonsContainer;
