import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';

export const EventContext = createContext();

export const EventProvider = ({ children }) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();
	const instance = Axios.create();
    const axios = setupCache(instance, { 
        storage: buildWebStorage(localStorage, 'axios-cache:')
      });

	const [
		events,
		setEvents
	] = useState([]);
	const [
		filteredEvents,
		setFilteredEvents
	] = useState([]);
	const [
		checkedItems,
		setCheckedItems
	] = useState({
		allEvents: false,
		warConflict: false,
		fiscalEconomic: false,
		worldGeopolitical: false,
		congressionalGovernment: false,
		scienceTechnology: false,
		naturalGeological: false
	});

	useEffect(
		() => {
			const fetchEvents = async () => {

				if (!isAuthenticated) {
					return; // Exit early if not authenticated
				}

				try {
					const token = await getAccessTokenSilently();
					const response = await axios.get(
						`${process.env.REACT_APP_API_BASE_URL}/ei/historical-events`,
						{
							headers: {
								Authorization: `Bearer ${token}`
							}
						}
					);
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					const data = await response.data;
					// console.log('Fetched events:', data); // Log fetched data
					if (
						Array.isArray(data) &&
						data.length > 0 &&
						data[0].Event &&
						data[0].Type &&
						data[0].Date
					) {
						setEvents(data); // Assuming data is already an array of events
						setFilteredEvents(data); // Initialize filtered events
					} else {
						console.error('Unexpected data structure:', data);
					}
				} catch (error) {
					console.error('Error fetching events:', error);
				}
			};
			fetchEvents();
		}, [ getAccessTokenSilently, isAuthenticated]
	);

	useEffect(
		() => {
			const filterEvents = () => {
				// console.log('Filtering events with:', checkedItems);

				if (checkedItems.allEvents) {
					setFilteredEvents(events);
				} else {
					const filtered = events.filter((event) => {
						if (checkedItems.warConflict && event.Type === 'Wars/Conflicts')
							return true;
						if (checkedItems.fiscalEconomic && event.Type === 'Fiscal/Economic')
							return true;
						if (
							checkedItems.worldGeopolitical &&
							event.Type === 'World/Geopolitical'
						)
							return true;
						if (
							checkedItems.congressionalGovernment &&
							event.Type === 'Congressional/Government'
						)
							return true;
						if (
							checkedItems.scienceTechnology &&
							event.Type === 'Science/Technology'
						)
							return true;
						if (
							checkedItems.naturalGeological &&
							event.Type === 'Natural/Geological'
						)
							return true;
						return false;
					});
					setFilteredEvents(filtered);
				}
			};

			filterEvents();
		},
		[
			checkedItems,
			events
		]
	);

	return (
		<EventContext.Provider
			value={{ checkedItems, setCheckedItems, filteredEvents }}
		>
			{children}
		</EventContext.Provider>
	);
};
