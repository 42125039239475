import '../../../css/SideMenus/CustomizeMenu/CustomizeMenu.css';
import { useState, useEffect, useContext } from 'react';
import { ReactComponent as GoodCheckIcon } from '../../../images/circle-checked-icon.svg';
import { ReactComponent as AlertTriangleIcon } from '../../../images/alert-triangle-icon.svg';
import PortfolioDropdown from './PortfolioDropdown';
import { CustomizeContext } from '../../../context/CustomizeContext';

const PortfolioTab = ({ rebase, rebaseNewCustomPortfolio, MIN_YEAR, yearToValidate, errorMessage, setYearToValidate }) => {
  const {
    customizeSettings,
    setPercentageState,
    setPortfolioData,
    saveCustomPortfolio,
    setActivePortfolioType,
    setActiveRebaseData,
  } = useContext(CustomizeContext);

  const [allocation, setAllocation] = useState({});
  const [showAddPortfolio, setShowAddPortfolio] = useState(false);
  const [portfolioName, setPortfolioName] = useState("");
  const [isError, setIsError] = useState([false, '']);
  const [portfolioLoading, setPortfolioLoading] = useState(false);

  useEffect(() => {
    if (customizeSettings.portfolioData) {
      const newAllocation = customizeSettings.portfolioData[customizeSettings.activePortfolioType.toLowerCase()] || {};
      setAllocation(newAllocation);
      setShowAddPortfolio(false);
    }

  }, [customizeSettings.activePortfolioType, customizeSettings.portfolioData]);

  const totalAllocation = Object.entries(allocation)
    .filter(([key]) => !key.endsWith("Percentage"))
    .reduce((acc, [, value]) => acc + value, 0);

  useEffect(() => {
    if (totalAllocation < 100) {
      setPercentageState('warning');
    } else if (totalAllocation === 100) {
      setPercentageState('good');
    } else {
      setPercentageState('bad');
    }
  }, [totalAllocation]);

  const handleAllocationChange = (field, value) => {
    const newValue = Math.max(0, Math.min(100, parseFloat(value) || 0));
    const textboxText = newValue.toString().replace(/^0+/, '') || '0';

    setAllocation((prevAllocation) => ({
      ...prevAllocation,
      [field]: parseFloat(textboxText),
    }));
  };

  const updatePortfolio = () => {
    setIsError([false, ""]);
    setPortfolioData(allocation);
    rebase();
  }

  const validatePortfolioName = () => {
    const trimmedName = portfolioName.trim();

    if (!trimmedName) {
      setIsError([true, "Portfolio name cannot be empty"]);
      return false;
    }

    if (!/^[A-Za-z]/.test(trimmedName)) {
      setIsError([true, "Portfolio name must start with a letter"]);
      return false;
    }

    const nameExists = Object.keys(customizeSettings.portfolioData || {}).some(
      (existingName) => existingName.toLowerCase() === trimmedName.toLowerCase()
    );

    if (nameExists) {
      setIsError([true, `You already have a portfolio with the name: ${portfolioName}`]);
      return false;
    }

    setIsError([false, ""]);;
    return true;
  };


  const savePortfolio = async () => {
    setPortfolioLoading(true);
    try {
      const addedPortfolio = await saveCustomPortfolio({ ...allocation, name: portfolioName.trim().toLowerCase() });
      if (addNewPortfolio === null) {
        throw new Error("Error adding portfolio.")
      }
      setPortfolioName("");
      return addedPortfolio;
    } catch (error) {
      return null;
    } finally {
      setPortfolioLoading(false);
    }
  };

  const addNewPortfolio = async () => {
    const isNameValid = validatePortfolioName();
    if (isNameValid) {
      const addedPortfolio = await savePortfolio();
      if (!addedPortfolio) {
        const defaultPortfolio = 'balanced';
        setIsError([true, "Error occurred while saving portfolio. Please try again later."]);
        setPortfolioData(customizeSettings.portfolioData[defaultPortfolio]);
        setActivePortfolioType(defaultPortfolio);
        return;
      } else if (addedPortfolio && customizeSettings.rebaseData.length > 0) {
        // If chart is in a rebased state and user adds a custom portfolio, send data to be rebased and display new portfolio.
        const { PortfolioType, ...rest } = addedPortfolio;
        const combinedPortfolioData = { ...customizeSettings.portfolioData, ...rest }
        setPortfolioData(combinedPortfolioData);
        const rebaseWithCustomPortfolio = rebaseNewCustomPortfolio(combinedPortfolioData, PortfolioType);
        setActiveRebaseData(rebaseWithCustomPortfolio);
        setActivePortfolioType(PortfolioType);
        setShowAddPortfolio(false);
      } else {
        // Chart not rebased. Set new portfolio data and update active portfolio.
        const { PortfolioType, ...rest } = addedPortfolio;
        setPortfolioData(rest);
        setActivePortfolioType(PortfolioType);
        setShowAddPortfolio(false);
      }
    }
  };

  const showAddPortfolioSection = () => {
    setIsError([false, ""]);
    setShowAddPortfolio(true);
  }
  const hideAddPortfolioSection = () => {
    setPortfolioName("");
    setIsError([false, ""]);
    setShowAddPortfolio(false);
  }

  const isDisabled = customizeSettings.percentageState === 'warning' || customizeSettings.percentageState === 'bad';

  return (
    <div className='tab-content'>
      <PortfolioDropdown portfolioType={customizeSettings.activePortfolioType} />
      <div className={`allocation-control ${customizeSettings.percentageState}`}>
        {['SmallStocks', 'LargeStocks', 'Bonds', 'Cash', 'Other'].map((field, index) => (
          <div key={index} className='allocation-item font-weight-400'>
            <label htmlFor={field}>
              {field === 'SmallStocks' ? 'Small Stocks' :
                field === 'LargeStocks' ? 'Large Stocks' :
                  field === 'Bonds' ? 'Bonds' :
                    field === 'Cash' ? 'Cash' :
                      'Other'}
            </label>
            <div className='input-container'>
              <input
                className='rounded-corners'
                type='number'
                id={field}
                value={allocation[field] || 0} // Safely access allocation values
                onChange={(e) => handleAllocationChange(field, e.target.value)}
                max={100}
              />
              <span className='percentage-symbol'>%</span>
            </div>
          </div>
        ))}

        <div className={`total-allocation-container ${customizeSettings.percentageState}`}>
          {customizeSettings.percentageState === 'good' && <GoodCheckIcon />}
          {customizeSettings.percentageState === 'warning' && <AlertTriangleIcon className='triangle-icon-warning' />}
          {customizeSettings.percentageState === 'bad' && <AlertTriangleIcon className='triangle-icon-bad' />}
          <span>Total allocation</span>
          <span className='total-allocation-text font-weight-400'>
            <span className={`total-allocation-var ${customizeSettings.percentageState} font-weight-600`}>
              {isNaN(totalAllocation) ? 0 : totalAllocation}%
            </span> <span className={`total-allocation-slash ${customizeSettings.percentageState}`}>/</span> 100%
          </span>
        </div>
      </div>

      {customizeSettings.percentageState !== 'good' &&
        <div className={`allocation-error-message ${customizeSettings.percentageState} font-weight-400`}>
          <p>Total allocation must be 100%</p>
        </div>
      }

      <button
        className='update-portfolio update-button customization-full-width font-weight-600'
        onClick={updatePortfolio}
        disabled={isDisabled}
      >Update Portfolio</button>

      {!showAddPortfolio &&
        <button
          className='save-custom-portfolio save-button customization-full-width font-weight-600'
          onClick={showAddPortfolioSection}
          disabled={isDisabled}
        >Save Custom Portfolio</button>
      }

      {showAddPortfolio && !portfolioLoading && (
        <div className={"enter-name-section"}>
          <input
            type='text'
            id='portfolioName'
            aria-label='enter portfolio name'
            value={portfolioName}
            onChange={(e) => setPortfolioName(e.target.value)}
            placeholder="Enter a name for your portfolio"
            className="rounded-corners portfolio-name-input customization-full-width"
          />
          <button
            className="submit-name-button customization-full-width update-portfolio"
            onClick={addNewPortfolio}
          >
            Submit
          </button>
          <button
            className="cancel-submit-name-button customization-full-width update-portfolio"
            onClick={hideAddPortfolioSection}
          >
            Cancel
          </button>

        </div>
      )}

      {showAddPortfolio && portfolioLoading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      {isError[0] && <p className="error-message">{isError[1]}</p>}
    </div>
  );
}

export default PortfolioTab;
