import React, { useState, useEffect, useContext } from 'react';
import ChartOverlayWrapper from './ChartOverlayWrapper';
import { MenuContext } from '../../context/MenuContext';
import { EconomicDataContext } from '../../context/EconomicDataContext';
import { fi } from 'date-fns/locale';

function RecessionsChart({selectedTool}) {
	const { selectedButton } = useContext(MenuContext);
	const [
			recessionsLegendOn,
			setRecessionsLegendOn
		] = useState(true);
	const { 
				recessions,
				loadingRecessions,
			} = useContext(EconomicDataContext);

	useEffect(() => {
			const checkLegendStatus = () => {
				try {
					const plot = document.getElementById('PlotlyGraph');
	
					if (!plot) {
						console.warn("PlotlyGraph element not found");
						return;
					}
	
					plot.on('plotly_restyle', (eventData) => {
						const visibilityChanges = eventData[0].visible; // Array of new visibility states
						const affectedTraceIndices = eventData[1]; // Array of affected trace indices
	
						affectedTraceIndices.forEach((traceIndex, idx) => {
							const traceVisibility = visibilityChanges[idx];
	
							if (traceIndex === 11) {
								const isOn = traceVisibility !== "legendonly";
								setRecessionsLegendOn(isOn); // Update state based on visibility
							}
						});
					});
				} catch (ex) {
					console.error("Error setting up legend status listener:", ex);
				}
			};
	
			checkLegendStatus();
	
			// Cleanup function to remove event listeners on unmount
			return () => {
				const plot = document.getElementById('PlotlyGraph');
				if (plot) {
					plot.removeAllListeners && plot.removeAllListeners('plotly_restyle');
				}
			};
		}, []);

	if (loadingRecessions || recessions.length === 0) {
		return <div>Loading Recessions chart...</div>;
	}

	const xAxisSettings = null;

	const yAxisSettings =
	{
		range: [
			0,
			1
		],
		showticklabels: false,
		title: '',
		showgrid: false,
		fixedrange: true,
	};

	const chartData = [
		{
			x: recessions.flatMap((range) => [range.start_date, range.end_date, null]),
			y: recessions.flatMap(() => [0, 1, null]),
			fill: 'toself',
			fillcolor: '#e0e0e0',
			opacity: 0.5,
			mode: 'none',
			name: `Recessions<br>(Vertical Bars)<br>National Bureau of<br> Economic Research`,
			yaxis: 'y',
			xaxis: 'x',
			legend: 'legend',
			showlegend: selectedButton === 'Events',
		}
	]


	const recessionData = recessionsLegendOn ? recessions : [];

	const shapes = recessionData.map((range) => ({
		type: 'rect',
		xref: 'x',
		yref: 'paper',
		x0: range.start_date,
		x1: range.end_date,
		y0: 0,
		y1: 1,
		fillcolor: '#e0e0e0',
		opacity: 0.5,
		line: {
			width: 0
		}
	}))

	return (
		<div style={{ width: '100%', minWidth: '300px' }}>
			<ChartOverlayWrapper
				singleChartData={chartData} 
				singleChartXAxisSettings={xAxisSettings} 
				singleChartYAxisSettings={yAxisSettings} 
				singleChartShapes={shapes}
				selectedTool={selectedTool}
			/>
		</div>
	);
}

export default React.memo(RecessionsChart); // Memo to keep charts from redrawing when exporting image
