import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const UserSearches = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'searchDate', name: 'Search Date', dataType: 'date' },
        {
            field: 'searchTerm',
            name: 'Search Term',
            dataType: 'string',
            styles: { minWidth: '30rem' },
        },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.searchDate),
        {
            count: true,
        }
    );

    const chartData = [
        {
            name: 'Total Searches',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.count),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="User Searches Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserSearches;
