import { DataTable } from 'primereact/datatable';

export const dataTableBuilder = (
    value,
    filters,
    onFilter,
    onValueChange,
    children,
    customDataTableOptions
) => {
    if (!value || !filters || !onFilter) {
        throw new Error('Missing props in dataTableBuilder');
    }

    return (
        <DataTable
            value={value}
            filters={filters}
            onFilter={onFilter}
            onValueChange={onValueChange}
            stripedRows
            filterDisplay="menu"
            paginator
            rows={10}
            resizableColumns={true}
            columnResizeMode="expand"
            sortMode="single"
            emptyMessage="No results found."
            {...customDataTableOptions}
        >
            {children}
        </DataTable>
    );
};
