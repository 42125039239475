import { createContext, useEffect, useMemo, useState } from 'react';
import { ERROR_TEXT } from '../constants';
import { useAxiosAuthenticated } from '../useAxiosAuthenticated';
import { reportsMeta } from '../reportsMeta';
import { noop } from 'lodash';

export const ReportContext = createContext();
export const ReportProvider = ({ children }) => {
    const [errorText, setErrorText] = useState('');

    const { get, tokenError } = useAxiosAuthenticated();
    useEffect(() => {
        if (tokenError) setErrorText(ERROR_TEXT.token);
    }, [tokenError]);

    ///////// Selected Report and Report Data
    const [hasMadeFirstRequest, setHasMadeFirstRequest] = useState(false);
    const [inFlight, setInFlight] = useState(false);
    const [selectedReport, _setSelectedReport] = useState();
    const [reportData, setReportData] = useState([]);

    const setSelectedReportId = (reportId) => {
        const rep = reportsMeta[reportId];
        if (!rep) {
            throw new Error('Invalid report id: ' + reportId);
        }

        setHasMadeFirstRequest(false);
        setInFlight(false);
        setErrorText('');
        setReportData([]);

        return _setSelectedReport(rep);
    };

    const generateRequestUrl = () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const url = new URL(`${baseUrl}${selectedReport.apiEndpoint}`);
        url.searchParams.append('CobrandId', paramCobrand);
        if (paramUnit) {
            url.searchParams.append('UnitId', paramUnit);
        }
        url.searchParams.append('From', paramFromDate.toISOString().split('T')[0]);
        url.searchParams.append('To', paramToDate.toISOString().split('T')[0]);

        return url.toString();
    };

    const getReportData = async () => {
        try {
            setInFlight(true);
            setErrorText('');
            const preRequestUrl = generateRequestUrl();
            const resp = await get(preRequestUrl);

            // This acts like a page+parameter hash check to make sure nothing has changed
            // since the request started (e.g. user moved to a different report)
            const postRequestUrl = generateRequestUrl();
            if (preRequestUrl !== postRequestUrl) return;

            setInFlight(false);
            setReportData(resp.data);
            setHasMadeFirstRequest(true);
        } catch (error) {
            console.error('Error fetching report data:', error);
            setErrorText(ERROR_TEXT.reportRequest);
            setInFlight(false);
        }
    };

    ///////// Reports Header
    const [cobrandOptions, setCobrandOptions] = useState([]);
    const [paramCobrand, setParamCobrand] = useState();
    useEffect(() => {
        const makeCobrandsRequest = async () => {
            try {
                setErrorText('');
                const url = process.env.REACT_APP_API_BASE_URL + '/staff/cobrands';
                const resp = await get(url);
                const newCobrandOptions = resp.data.map((x) => ({
                    id: x.cobrandId,
                    name: x.description,
                }));
                setCobrandOptions(newCobrandOptions);
                setParamCobrand(newCobrandOptions[0].id);
            } catch {
                setErrorText(ERROR_TEXT.cobrands);
            }
        };
        makeCobrandsRequest();
    }, []);

    const allUnitsObject = useMemo(() => ({ id: 0, name: 'All Units' }), []);
    const [unitOptions, setUnitOptions] = useState(allUnitsObject);

    useEffect(() => {
        if (!paramCobrand) return;

        const makeUnitsRequest = async () => {
            try {
                setErrorText('');
                const url = `${process.env.REACT_APP_API_BASE_URL}/reports/units?cobrandId=${paramCobrand}`;
                const resp = await get(url);
                setUnitOptions([
                    allUnitsObject,
                    ...resp.data.map((x) => ({ id: x.unitId, name: x.name })),
                ]);
            } catch {
                setErrorText(ERROR_TEXT.cobrands);
            }
        };
        makeUnitsRequest();
    }, [paramCobrand]);

    const [paramUnit, setParamUnit] = useState(allUnitsObject.id);
    const [paramFromDate, setParamFromDate] = useState(
        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000 * 2)
    );
    const [paramToDate, setParamToDate] = useState(new Date());

    ///////// Table filter reset on data change
    // This is to address a bug with the Prime React DataTable where 
    // the onValueChange is not always correctly triggered. A reset
    // of the table filters forces this function to re-evaluate.
    const [resetFiltersFunc, setResetFiltersFunc] = useState(noop);
    useEffect(() => {
        if (resetFiltersFunc) resetFiltersFunc();
    }, [reportData]);

    return (
        <ReportContext.Provider
            value={{
                // Selected report
                selectedReport,
                setSelectedReportId,
                errorText,

                // Report data
                reportData,
                hasMadeFirstRequest,
                inFlight,

                // Header
                cobrandOptions,
                paramCobrand,
                setParamCobrand,

                unitOptions,
                paramUnit,
                setParamUnit,

                paramFromDate,
                setParamFromDate,

                paramToDate,
                setParamToDate,

                // Run report
                getReportData,

                // Reset Filters
                setResetFiltersFunc,
            }}
        >
            {children}
        </ReportContext.Provider>
    );
};
