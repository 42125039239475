import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';

const UserResources = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'viewedDate', name: 'Viewed Date', dataType: 'date' },
        { field: 'resourceType', name: 'Type', dataType: 'multiselect' },
        {
            field: 'resourceTitle',
            name: 'Title',
            dataType: 'multiselect',
            styles: { minWidth: '30rem' },
        },
    ];

    const [filteredData, setFilteredData] = useState([]);

        const rolledUp = dataRollUp(
            filteredData,
            (row) => formatDateToIsoExtDate(row.viewedDate),
            {
                count: true,
            }
        );

        const chartData = [
            {
                name: 'Total Views',
                x: rolledUp.map((x) => x.groupByField),
                y: rolledUp.map((x) => x.count),
            },
        ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="Resource Views Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default UserResources;
