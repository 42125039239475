import { FilterMatchMode, FilterOperator } from 'primereact/api';
import {
    booleanColumnBuilder,
    dateColumnBuilder,
    multiSelectColumnBuilder,
    numericColumnBuilder,
    stringColumnBuilder,
} from './columnBuilder';
import _ from 'lodash';

export const convertStringDateToJsDates = (table, columns) => {
    const dateColumns = columns.filter((x) => x.dataType === 'date').map((x) => x.field);

    // The Prime DataTable allows for easily rewriting the logic for displaying dates
    // but if you want to customize the logic for filtering you have to rewrite basically
    // the whole filtering system. This means our UTC dates that we pass in get auto
    // converted to local and all the filtering becomes a day off.
    // Simple fix for this is we strip all the UTC data and parse each data as if 
    // it's midnight in the user's local timezone.
    const utcToLocalMidnight = (dateString) => {
        const [year, month, day] = dateString.split("T")[0].split("-").map(Number);
        return new Date(year, month - 1, day);
    }

    table.forEach((row) => {
        dateColumns.forEach((column) => {
            row[column] = row[column] ? utcToLocalMidnight(row[column]) : row[column];
        });
    });
};

export const convertBooleansToStringMultiselect = (table, columns) => {
    const booleanColumns = columns.filter((x) => x.dataType === 'boolean');

    table.forEach((row) => {
        booleanColumns.forEach((column) => {
            row[column.field] = row[column.field] ? 'Yes' : 'No';
        });
    });

    booleanColumns.forEach((x) => (x.dataType = 'multiselect'));
};

export const decorateMultiselectColumnsWithOptions = (table, columns) => {
    const multiSelectColumns = columns.filter((x) => x.dataType === 'multiselect');

    multiSelectColumns.forEach((column) => {
        column.multiselectOptions = _(table)
            .map((y) => y[column.field])
            .filter(Boolean)
            .uniq()
            .sort()
            .value();
    });
};

export const getFilterDefaultByDataType = (dataType) => {
    switch (dataType) {
        case 'string':
            return {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
            };
        case 'date':
            return {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_AFTER }],
            };
        case 'multiselect':
            return {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
            };
        case 'numeric':
            return {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
                ],
            };
        case 'boolean':
            return {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
            };
        default:
            throw new Error(
                'Unknown dataType provided to getFilterDefaultByDataType: ' + dataType
            );
    }
};

export const buildColumnByDataType = (column) => {
    switch (column.dataType) {
        case 'string':
            return stringColumnBuilder(column.field, column.name, column.styles);
        case 'date':
            return dateColumnBuilder(column.field, column.name, column.styles);
        case 'multiselect':
            return multiSelectColumnBuilder(
                column.field,
                column.name,
                column.multiselectOptions,
                column.styles
            );
        case 'numeric':
            return numericColumnBuilder(column.field, column.name, column.styles);
        case 'boolean':
            // Note this is NOT used by the <StandardizedDataTable />
            return booleanColumnBuilder(column.field, column.name, column.styles);
        default:
            throw new Error(
                'Unknown dataType provided to buildColumnByDataType: ' + column?.dataType
            );
    }
};
