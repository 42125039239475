import React, { useContext } from 'react';
import '../../../css/Card/PageContentCard.css';
import presentationIcon from '../../../images/presentation_icon.svg';
import presentationIconOn from '../../../images/presentation_icon_on.svg';
import exportIcon from '../../../images/export_icon.svg';
import exportIconOn from '../../../images/export_icon_on.svg';
import { MenuContext } from '../../../context/MenuContext';
import { EI_PAGES } from '../../../constants';

const PageContentCardHeader = ({
    handleExportOptionsClick,
    date,
    isExportViewMenuOpen,
}) => {

    const monthYearDate = date.replace(/^\d{1,2}\s/, "");
    const { selectedMenuItem, isPresentationMode, togglePresentationMode } = useContext(MenuContext);


    return (
        <div className="pagecontent-card-header-container">
            <p className="pagecontent-card-title">{selectedMenuItem.name}</p>
            <div className="pagecontent-card-subtitle-container">
                <p className="pagecontent-card-subtitle">
                    {selectedMenuItem.id === EI_PAGES.fullAndex ? `$1 • January 1926 - ${monthYearDate} • All Assets` : ''}
                    {selectedMenuItem.id === EI_PAGES.percentageReturns ? `$1 • January 1926 - ${monthYearDate} • All Assets` : ''}
                    {selectedMenuItem.id === EI_PAGES.past30Years ? `$10,000 • January 1994 - ${monthYearDate} • All Assets` : ''}
                </p>
                <img
                    src={isPresentationMode ? presentationIconOn : presentationIcon}
                    alt="Presentation Icon"
                    className="presentation-icon"
                    onClick={togglePresentationMode}
                />
                <img
                    src={isExportViewMenuOpen ? exportIconOn : exportIcon}
                    alt="Export Chart Icon"
                    className={`export-icon ${isExportViewMenuOpen ? 'icon-selected' : ''}`}
                    onClick={handleExportOptionsClick}
                />
            </div>
        </div>
    );
};

export default PageContentCardHeader;
