import React, { createContext, useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';
import { useAuth0 } from '@auth0/auth0-react';

export const EconomicDataContext = createContext();
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const EconomicDataProvider = ({ children }) => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const cacheTTL = 60 * 60 * 2000 // 2 hours
    const instance = Axios.create();
    const axios = setupCache(instance, {
        storage: buildWebStorage(localStorage, 'axios-cache:'),
        ttl: cacheTTL
    });

    const [
        sbbi,
        setSbbi
    ] = useState([]);
    const [
        loadingSbbi,
        setLoadingSbbi
    ] = useState(true);

    const [
        historicalEvents,
        setHistoricalEvents
    ] = useState([]);
    const [
        loadingEvents,
        setLoadingEvents
    ] = useState(true);

    const [
        recessions,
        setRecessions
    ] = useState([]);
    const [
        loadingRecessions,
        setLoadingRecessions
    ] = useState(true);

    const [
        economicCycles,
        setEconomicCycles
    ] = useState([]);
    const [
        loadingEconomicCycles,
        setLoadingEconomicCycles
    ] = useState(true);

    const [
        commodities,
        setCommodities
    ] = useState([]);
    const [
        loadingCommodities,
        setLoadingCommodities
    ] = useState(true);

    const [
        interestRateInflationData,
        setInterestRateInflationData
    ] = useState([]);
    const [
        loadingInterestRateInflationData,
        setLoadingInterestRateInflationData
    ] = useState(true);

    const [
        sP500PEData,
        setSP500PEData
    ] = useState([]);

    const [
        loadingSP500Data,
        setLoadingSP500Data
    ] = useState(true);

    const [
        governmentData,
        setGovernmentData
    ] = useState([]);

    const [
        loadingGovernmentData,
        setLoadingGovernmentData
    ] = useState(true);

    const [
        originalChartData, 
        setOriginalChartData
    ] = useState([]);

    const [
        zoomedChartData, 
        setZoomedChartData
    ] = useState([]);

    const [
        zoomedDates, 
        setZoomedDates
    ] = useState([]);

    useEffect(
        () => {
            const fetchData = async () => {

                if (!isAuthenticated) {
					return; // Exit early if not authenticated
				}

                try {
                    const token = await getAccessTokenSilently();
                    const allChartsResponse = await axios.get(`${API_BASE_URL}/ei/all-charts`,
                        {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        id: 'all-charts-data'
                    });

                    const allChartsData = await allChartsResponse.data;

                    setSbbi(await allChartsData.SbbiDefault);
                    setLoadingSbbi(false);

                    setHistoricalEvents(await allChartsData.HistoricalEventsData);
                    setLoadingEvents(false);

                    setRecessions(await allChartsData.RecessionsData);
                    setLoadingRecessions(false);

                    setEconomicCycles(await allChartsData.ExpansionsContractionsData);
                    setLoadingEconomicCycles(false);

                    setCommodities(await allChartsData.CommoditiesData);
                    setLoadingCommodities(false);

                    setInterestRateInflationData(await allChartsData.PrimeInterestVsInflationData);
                    setLoadingInterestRateInflationData(false);

                    setSP500PEData(await allChartsData.PERatioData);
                    setLoadingSP500Data(false);

                    setGovernmentData(await allChartsData.GovernmentData);
                    setLoadingGovernmentData(false);

                } catch (e) {
                    console.error(e);
                    setLoadingSbbi(false);
                    setLoadingEvents(false);
                    setLoadingRecessions(false);
                    setLoadingEconomicCycles(false);
                    setLoadingCommodities(false);
                    setLoadingInterestRateInflationData(false);
                    setLoadingSP500Data(false);
                }
            };
            fetchData();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ getAccessTokenSilently, isAuthenticated ]
    );

    const contextValue = useMemo(() => ({
        sbbi,
        loadingSbbi,
        historicalEvents,
        loadingEvents,
        recessions,
        loadingRecessions,
        economicCycles,
        loadingEconomicCycles,
        commodities,
        loadingCommodities,
        interestRateInflationData,
        loadingInterestRateInflationData,
        sP500PEData,
        loadingSP500Data,
        governmentData,
        loadingGovernmentData,
        originalChartData,
        setOriginalChartData,
        zoomedChartData,
        setZoomedChartData,
        zoomedDates,
        setZoomedDates,
    }), [
        sbbi,
        loadingSbbi,
        historicalEvents,
        loadingEvents,
        recessions,
        loadingRecessions,
        economicCycles,
        loadingEconomicCycles,
        commodities,
        loadingCommodities,
        interestRateInflationData,
        loadingInterestRateInflationData,
        sP500PEData,
        loadingSP500Data,
        governmentData,
        loadingGovernmentData,
        originalChartData,
        setOriginalChartData,
        zoomedChartData,
        setZoomedChartData,
        zoomedDates,
        setZoomedDates,
    ]);

    return (
        <EconomicDataContext.Provider
            value={contextValue}
        >
            {children}
        </EconomicDataContext.Provider>
    );
};
