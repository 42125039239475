import React from 'react';
import { ReactComponent as FilterIcon } from '../../images/filter_icon.svg';

const ReportChartLayoutWrapper = ({ children }) => {
    return (
        <div>
            <div className="flex justify-center flex-wrap gap-1 mt-6">{children}</div>
            <div className="flex justify-center text-gray-500 text-sm font-bold pt-2 ">
                Use the table filters (
                <div>
                    <FilterIcon className="inline-block" />
                </div>
                ) to customize the charts
            </div>
        </div>
    );
};

export default ReportChartLayoutWrapper;
