import _ from 'lodash';

// data - Object[] - the full table to operate over
// groupByFunc - function - means of mapping a given row down to a particular value which will be grouped over
// options.count - boolean - get the total count
// options.sumFields - string[] - fields to get a sum of
// options.avgFields - string[] - fields to get an average of
// options.countFields - string[] - Count of truthy values on the
// options.countDistinctFields - string[]

// Returns - Object[] - groupByField is the only guaranteed result, the others depend on options. e.g.
// [{groupByField: '2024-08-01', count: 34, <fieldName>Count: 32, <fieldName>Sum: 124}]

export const dataRollUp = (data, groupByFunc, options) => {
    // lodash will take a string `groupBy` but it will perform unexpectedly with
    // the most common argument passed, Dates, so we outright enforce
    // functions only for now
    if (!_.isFunction(groupByFunc)) {
        throw new Error('groupBy must be a function');
    }

    const grouped = _.groupBy(data, groupByFunc);

    return Object.keys(grouped)
        .sort()
        .map((key) => {
            let newObj = { groupByField: key };

            if (options.count) {
                newObj.count = grouped[key].length;
            }

            const isNonEmptyRecord = (x) => x !== '' && x !== null && x !== undefined;

            if (options.sumFields?.length) {
                options.sumFields.forEach((field) => {
                    newObj[field + 'Sum'] = _(grouped[key])
                        .map((x) => x[field])
                        .filter(isNonEmptyRecord)
                        .sum();
                });
            }

            if (options.avgFields?.length) {
                options.avgFields.forEach((field) => {
                    newObj[field + 'Avg'] = _(grouped[key])
                        .map((x) => x[field])
                        .filter(isNonEmptyRecord)
                        .mean();
                });
            }

            if (options.countFields?.length) {
                options.countFields.forEach((field) => {
                    newObj[field + 'Count'] = _(grouped[key])
                        .map((x) => x[field])
                        .filter(isNonEmptyRecord)
                        .value().length;
                });
            }

            if (options.countDistinctFields?.length) {
                options.countDistinctFields.forEach((field) => {
                    newObj[field + 'CountDistinct'] = _(grouped[key])
                        .map((x) => x[field])
                        .filter(isNonEmptyRecord)
                        .uniq()
                        .value().length;
                });
            }

            return newObj;
        });
};
