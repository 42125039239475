import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

export const useAxiosAuthenticated = () => {
    const { getAccessTokenSilently } = useAuth0();
    const tokenRef = useRef('');
    const [tokenError, _setTokenError] = useState();

    useEffect(() => {
        const getToken = async () => {
            try {
                tokenRef.current = await getAccessTokenSilently();
            } catch (error) {
                _setTokenError(error);
            }
        };
        getToken();
    }, [getAccessTokenSilently]);

    const get = async (url) => {
        // Delay the request until the token is back
        while (!tokenRef.current) {
            await new Promise((resolve) => setTimeout(resolve, 250));
        }

        // If we need to set a custom config we'd build this to
        // merge the contents of a passed `config` and this one
        // but I'm going to assume YAGNI and not build for now
        const config = {
            headers: {
                Authorization: `Bearer ${tokenRef.current}`,
            },
        };

        return axios.get(url, config);
    };

    const post = async (url) => {
        while (!tokenRef.current) {
            await new Promise((resolve) => setTimeout(resolve, 250));
        }

        const config = {
            headers: {
                Authorization: `Bearer ${tokenRef.current}`,
            },
        };

        return axios.post(url, config);
    };

    return { get, post, tokenError };
};
