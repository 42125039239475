import React, { useContext } from 'react';
import menuToggle from '../../images/menu_toggle.png';
import menuToggleClosed from '../../images/menu_toggle_closed.svg';
import '../../css/Header/Header.css';
import { MenuContext } from '../../context/MenuContext';
import { PAGE_TYPE } from '../../constants';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const Header = () => {
    const { isMenuOpen, toggleMenuOpen, selectedMenuItem } = useContext(MenuContext);

    const { logout } = useAuth0();

    return (
        <div className="header">
            <div className="left-section">
                <img
                    src={isMenuOpen ? menuToggle : menuToggleClosed}
                    alt="Menu Toggle"
                    className="menu-toggle"
                    onClick={toggleMenuOpen}
                />
                <span className="header-text">
                    {selectedMenuItem.pageType === PAGE_TYPE.educatedInvestor
                        ? 'Andex chart'
                        : 'Reports'}
                </span>
            </div>
            <Button
                variant="outlined"
                onClick={() =>
                    logout({ logoutParams: { returnTo: window.location.origin } })
                }
                sx={{ marginRight: '16px' }}
            >
                Log Out
            </Button>
        </div>
    );
};

export default Header;
