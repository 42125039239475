import { noop } from 'lodash';
import useFilters from './useFilters';
import { dataTableBuilder } from './dataTableBuilder';
import {
    buildColumnByDataType,
    convertBooleansToStringMultiselect,
    convertStringDateToJsDates,
    decorateMultiselectColumnsWithOptions,
} from './primeUtils';
import { Button } from '@mui/material';
import { useContext, useEffect } from 'react';
import { ReportContext } from '../../context/ReportContext';

const StandardizedDataTable = ({
    data,
    columns,
    onValueChange = noop,
    rootClassName = '',
}) => {
    const { setResetFiltersFunc } = useContext(ReportContext);

    // We're going to be doing some in-place manipulation of data to make things
    // easy so to keep that kosher we only operate over a duplicated set of the
    // passed in data
    const dataCopy = data.map((x) => ({ ...x }));
    const columnsCopy = columns.map((x) => ({ ...x }));

    const [filters, setFilters, resetFilters] = useFilters(columnsCopy);
    useEffect(() => {
        setResetFiltersFunc(resetFilters);
    }, []);

    const onFilter = (e) => setFilters(e.filters);

    // Intercept any boolean types and convert the booleans into
    // strings for 'Yes' and 'No', then set the data type to multiselect
    convertBooleansToStringMultiselect(dataCopy, columnsCopy);

    // Decorate the multi-selectors with an array of all the unique options
    // from the data to serve as options in the filter
    decorateMultiselectColumnsWithOptions(dataCopy, columnsCopy);

    // Convert the date's in the data to JS date objects
    // This uses the default parser so must ensure all dates are in
    // a standard ISO shape
    convertStringDateToJsDates(dataCopy, columnsCopy);

    // Assemble the DataTable friendly <Column>'s
    const childrenColumns = columnsCopy.map(buildColumnByDataType);

    return (
        <div className={`max-w-[93vw] ${rootClassName}`}>
            <Button onClick={resetFilters} variant="outlined" id="reset-filters">
                Reset Table Filters
            </Button>
            {dataTableBuilder(
                dataCopy,
                filters,
                onFilter,
                onValueChange,
                childrenColumns
            )}
        </div>
    );
};

export default StandardizedDataTable;
