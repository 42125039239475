import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { MenuContext } from '../../context/MenuContext';
import FullAndexChart from '../Charts/FullAndexChart';
import EconomicCyclesChart from '../Charts/EconomicCyclesChart';
import RecessionsChart from '../Charts/RecessionsChart';
import CommoditiesChart from '../Charts/CommoditiesChart';
import InterestRateInflationChart from '../Charts/InterestRateInflationChart';
import SBBI30YearChart from '../Charts/SBBI30YearChart';
import PortfolioChart from '../Charts/PortfolioChart';
import AssetFlowsChart from '../Charts/AssetFlowsChart';
import Returns30YearTable from '../Tables/Returns30YearTable';
import ReturnsDefaultTable from '../Tables/ReturnsDefaultTable';
import ChartTypeButtonsContainer from '../ChartTypeButtonsContainer/ChartTypeButtonsContainer';
import ModebarContainer from '../ChartTools/ModebarContainer';
import PageContentCard from '../Cards/PageContentCard';
import SaveViewMenu from '../Cards/Headers/SaveViewMenu';
import WorldEventsMenu from '../WorldEventsMenu/WorldEventsMenu';
import CustomizePopup from '../SideMenus/CustomizeMenu/CustomizePopup';
import Footer from '../Footer/Footer';
import CustomizeButton from '../Buttons/CustomizeButton';
import { CustomizeContext, CustomizeProvider } from '../../context/CustomizeContext';
import { getThisMonthsDate } from '../../helpers';
import { EI_PAGES } from '../../constants';
import { EconomicDataProvider } from '../../context/EconomicDataContext';
import { EventProvider } from '../../context/EventContext';

const EducatedInvestorPaneInternal = ({ dimensions }) => {
    const {
        selectedMenuItem,
        isPresentationMode,
        selectedButton,
        isWorldEventsMenuVisible,
        setIsWorldEventsMenuVisible,
        isMenuOpen,
    } = useContext(MenuContext);

    const { customizeSettings, setIsCustomizeMenuVisible } = useContext(CustomizeContext);
    const [updateCounter, setUpdateCounter] = useState(0);
    useEffect(() => {
        setUpdateCounter((x) => x + 1);
    }, [
        isMenuOpen,
        isPresentationMode,
        selectedButton,
        isWorldEventsMenuVisible,
        customizeSettings.isCustomizeMenuVisible,
        dimensions,
    ]);

    useEffect(() => {
        if (updateCounter % 2 === 0) {
            setUpdateCounter((x) => x + 1);
        }
    }, [updateCounter]);

    useEffect(() => {
        if (
            selectedMenuItem.id !== EI_PAGES.fullAndex &&
            customizeSettings.isCustomizeMenuVisible
        ) {
            setIsCustomizeMenuVisible(false);
        }

        if (selectedButton !== 'Full Andex' && customizeSettings.isCustomizeMenuVisible) {
            setIsCustomizeMenuVisible(false);
        }
    }, [selectedMenuItem, selectedButton]);

    const modebarOnForSideMenuItems = [EI_PAGES.fullAndex]; // Leave open for admin setting on off later
    const pageContentRef = useRef();
    const [isExportViewMenuOpen, setIsExportViewMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let [selectedTool, setSelectedTool] = useState('PointerTool');

    useEffect(() => {
        if (selectedButton === 'Events' && selectedMenuItem.id === EI_PAGES.fullAndex) {
            setIsWorldEventsMenuVisible(true);
        } else {
            setIsWorldEventsMenuVisible(false);
        }
    }, [selectedButton, selectedMenuItem]);

    const handleExportOptionsClick = () => {
        setIsExportViewMenuOpen(!isExportViewMenuOpen);
    };

    const renderCharts = () => {
        if (updateCounter % 2 === 0) {
            return <Fragment />;
        }
        if (selectedMenuItem.id === 'default') {
            return <Fragment>Temporary default page</Fragment>;
        } else if (selectedMenuItem.id === EI_PAGES.fullAndex) {
            switch (selectedButton) {
                case 'Full Andex':
                    return (
                        <Fragment>
                            <FullAndexChart selectedTool={selectedTool} />
                        </Fragment>
                    );
                case 'Expansion, contraction & recovery periods':
                    return <EconomicCyclesChart selectedTool={selectedTool} />;
                case 'Recessions':
                    return (
                        <RecessionsChart
                            className="RecessionsChart"
                            showLegend={true}
                            selectedTool={selectedTool}
                        />
                    );
                case 'Commodities':
                    return <CommoditiesChart selectedTool={selectedTool} />;
                case 'Prime interest rate vs inflation %':
                    return <InterestRateInflationChart selectedTool={selectedTool} />;
                case 'Events':
                    return (
                        <RecessionsChart
                            className="RecessionsChart"
                            selectedTool={selectedTool}
                        />
                    );
                default:
                    return null;
            }
        } else if (selectedMenuItem.id === EI_PAGES.past30Years) {
            return (
                <div style={{ width: '100%', backgroundColor: '#fff' }}>
                    <SBBI30YearChart />
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '25%',
                                padding: 20,
                            }}
                        >
                            <PortfolioChart assetMix="global" />
                        </div>
                        <div style={{ flex: 1, padding: 20 }}>
                            <AssetFlowsChart />
                        </div>
                    </div>
                    <Returns30YearTable />
                </div>
            );
        } else if (selectedMenuItem.id === EI_PAGES.percentageReturns) {
            return (
                <div style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}>
                    <ReturnsDefaultTable />
                </div>
            );
        }
    };
    return (
        <>
            {selectedMenuItem.id === EI_PAGES.fullAndex && (
                <div
                    className={`buttons-container ${
                        selectedButton === 'Full Andex'
                            ? 'chart-type-customize-button-container'
                            : ''
                    }`}
                >
                    <div className="chart-types-buttons-container">
                        <ChartTypeButtonsContainer />
                    </div>
                    {selectedButton === 'Full Andex' && (
                        <div className="customize-button-container">
                            <CustomizeButton
                                customizeSettings={customizeSettings}
                                setIsCustomizeMenuVisible={() =>
                                    setIsCustomizeMenuVisible(
                                        !customizeSettings.isCustomizeMenuVisible
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
            )}
            <div className="main-content expanded">
                {isLoading && <div className="loading-indicator">Processing...</div>}
                {modebarOnForSideMenuItems.includes(selectedMenuItem.id) && (
                    <div className="">
                        <ModebarContainer
                            selectedTool={selectedTool}
                            setSelectedTool={setSelectedTool}
                        />
                    </div>
                )}
                <PageContentCard
                    ref={pageContentRef}
                    date={getThisMonthsDate()}
                    handleExportOptionsClick={handleExportOptionsClick}
                    isExportViewMenuOpen={isExportViewMenuOpen}
                >
                    {isExportViewMenuOpen && (
                        <div className="save-view-menu-container">
                            <SaveViewMenu
                                pageContentRef={pageContentRef}
                                setIsLoading={setIsLoading}
                                setIsExportViewMenuOpen={setIsExportViewMenuOpen}
                            />
                        </div>
                    )}
                    {renderCharts()}
                </PageContentCard>
                {isWorldEventsMenuVisible && (
                    <WorldEventsMenu onClose={() => setIsWorldEventsMenuVisible(false)} />
                )}
                {customizeSettings.isCustomizeMenuVisible && <CustomizePopup />}
            </div>
            {!isPresentationMode && <Footer />}
        </>
    );
};

const EducatedInvestorPane = ({ dimensions }) => {
    return (
        <EventProvider>
            <EconomicDataProvider>
                <CustomizeProvider>
                    <EducatedInvestorPaneInternal dimensions={dimensions} />
                </CustomizeProvider>
            </EconomicDataProvider>
        </EventProvider>
    );
};

export default EducatedInvestorPane;
