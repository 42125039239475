import { useContext, useState } from 'react';
import { ReportContext } from '../../context/ReportContext';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ReportsHeader = () => {
    const {
        paramCobrand,
        setParamCobrand,
        cobrandOptions,
        unitOptions,
        paramUnit,
        setParamUnit,
        paramFromDate,
        setParamFromDate,
        paramToDate,
        setParamToDate,
        getReportData,
        inFlight,
    } = useContext(ReportContext);

    const isLoading = inFlight || !cobrandOptions.length || unitOptions.length <= 1;

    return (
        <div className="my-4 p-3">
            <Grid container spacing={3} alignItems="center">
                <Grid size={{ xs: 8, md: 3, xl: 2 }}>
                    <FormControl fullWidth variant="outlined" disabled={isLoading}>
                        <InputLabel id="cobrand-label">Cobrand</InputLabel>
                        <Select
                            labelId="cobrand-label"
                            value={paramCobrand}
                            onChange={(e) => setParamCobrand(e.target.value)}
                            label="Cobrand"
                        >
                            {cobrandOptions.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid size={{ xs: 8, md: 3, xl: 2 }}>
                    <FormControl fullWidth variant="outlined" disabled={isLoading}>
                        <InputLabel id="unit-label">unit</InputLabel>
                        <Select
                            labelId="unit-label"
                            value={paramUnit}
                            onChange={(e) => setParamUnit(e.target.value)}
                            label="unit"
                        >
                            {unitOptions.map((x) => (
                                <MenuItem key={x.id} value={x.id}>
                                    {x.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid size={{ xs: 8, md: 3, xl: 2 }}>
                    <DatePicker
                        label="From Date"
                        value={paramFromDate}
                        onChange={setParamFromDate}
                        sx={{ width: '100%' }}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid size={{ xs: 8, md: 3, xl: 2 }}>
                    <DatePicker
                        label="To Date"
                        value={paramToDate}
                        onChange={setParamToDate}
                        sx={{ width: '100%' }}
                        disabled={isLoading}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 2 }}>
                    <Button
                        variant="contained"
                        onClick={getReportData}
                        disabled={isLoading}
                    >
                        Run
                        {isLoading && (
                            <CircularProgress sx={{ marginLeft: '12px' }} size="1rem" />
                        )}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default ReportsHeader;
