import { useContext, useState } from 'react';
import StandardizedDataTable from '../../PrimeAids/StandardizedDataTable';
import { ReportContext } from '../../../context/ReportContext';
import { dataRollUp } from '../../PlotlyAids/dataRollUps';
import { formatDateToIsoExtDate } from '../../../helpers';
import ReportLineChart from '../../PlotlyAids/ReportLineChart';
import ReportChartLayoutWrapper from '../../PlotlyAids/ReportChartLayoutWrapper';

const CreditEnrollment = () => {
    const { reportData } = useContext(ReportContext);

    const columns = [
        { field: 'username', name: 'Username', dataType: 'string' },
        { field: 'firstName', name: 'First', dataType: 'string' },
        {
            field: 'lastName',
            name: 'Last',
            dataType: 'string',
            styles: { minWidth: '12rem' },
        },
        { field: 'enrollmentDate', name: 'Enrollment Date', dataType: 'date' },
        { field: 'enrollmentId', name: 'Enrollment Id', dataType: 'numeric' },
        {
            field: 'creditReportLastGeneratedDate',
            name: 'Last Report Date',
            dataType: 'date',
        },
        {
            field: 'completedEnrollment',
            name: 'Enrollment Complete',
            dataType: 'boolean',
        },
        { field: 'hasFeatures', name: 'Has Features', dataType: 'boolean' },
        { field: 'featuresRemovedDate', name: 'Features Removed', dataType: 'date' },
    ];

    const [filteredData, setFilteredData] = useState([]);

    const rolledUp = dataRollUp(
        filteredData,
        (row) => formatDateToIsoExtDate(row.enrollmentDate),
        {
            count: true,
        }
    );

    const chartData = [
        {
            name: 'Total Enrollments',
            x: rolledUp.map((x) => x.groupByField),
            y: rolledUp.map((x) => x.count),
        },
    ];

    return (
        <div>
            <StandardizedDataTable
                data={reportData}
                columns={columns}
                onValueChange={setFilteredData}
            />
            <ReportChartLayoutWrapper>
                <ReportLineChart
                    data={chartData}
                    chartTitle="Credit Enrollments Over Time"
                />
            </ReportChartLayoutWrapper>
        </div>
    );
};

export default CreditEnrollment;
